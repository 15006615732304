/*body {*/
/*  margin: 0;*/
/*  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',*/
/*    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',*/
/*    sans-serif;*/
/*  -webkit-font-smoothing: antialiased;*/
/*  -moz-osx-font-smoothing: grayscale;*/
/*}*/

/*code {*/
/*  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',*/
/*    monospace;*/
/*}*/

@font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 300;
    font-display: swap;
    src: url(/public/Roboto.ttf) format('truetype')
}

@font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/roboto/v30/KFOmCnqEu92Fr1Mu4mxP.ttf) format('truetype')
}

@font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/roboto/v30/KFOlCnqEu92Fr1MmEU9fBBc9.ttf) format('truetype')
}

@font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/roboto/v30/KFOlCnqEu92Fr1MmWUlfBBc9.ttf) format('truetype')
}

.h1, .h2, .h3, .h4, .h5, .h6, h1, h2, h3, h4, h5, h6 {
    margin-bottom: .5rem;
    font-family: inherit;
    line-height: 1.5;
    color: #1a3353
}

.h1, h1 {
    font-size: 30px
}

.h2, h2 {
    font-size: 22px
}

.h3, h3 {
    font-size: 21px
}

.h4, h4 {
    font-size: 17px
}

.h5, h5 {
    font-size: 14px
}

.h6, h6 {
    font-size: 10px
}

p {
    color: #72849a;
    line-height: 1.8
}

code {
    margin: 0 1px;
    background: #f7f7f8;
    padding: .2em .4em;
    border-radius: 3px;
    font-size: .9em;
    color: #ff6b72
}

.hl-code code {
    background: 0 0;
    border-radius: 3px;
    font-size: 1em;
    border: 0
}

.display-1 {
    font-size: 6rem;
    font-weight: 300;
    line-height: 1.5
}

.display-2, .display-3, .display-4 {
    font-size: 5rem;
    font-weight: 300;
    line-height: 1.5
}

.display-3, .display-4 {
    font-size: 4rem
}

.display-4 {
    font-size: 3rem;
    line-height: 1
}

code, hr {
    border: 1px solid #e6ebf1
}

body {
    color: #455560
}

.avatar-status-name {
    font-weight: 500;
    color: #1a3353
}

.ant-avatar-blue {
    background: rgba(62, 121, 247, .1);
    color: #3e79f7
}

.ant-avatar-purple {
    background: rgba(164, 97, 216, .1);
    color: #a461d8
}

.ant-avatar-cyan {
    background: rgba(4, 209, 130, .1);
    color: #04d182
}

.ant-avatar-green {
    background: rgba(33, 181, 115, .1);
    color: #21b573
}

.ant-avatar-magenta, .ant-avatar-pink {
    background: rgba(235, 47, 150, .1);
    color: #eb2f96
}

.ant-avatar-red {
    background: rgba(222, 68, 54, .1);
    color: #de4436
}

.ant-avatar-orange {
    background: rgba(250, 140, 22, .1);
    color: #fa8c16
}

.ant-avatar-yellow {
    background: rgba(250, 219, 20, .1);
    color: #fadb14
}

.ant-avatar-volcano {
    background: rgba(255, 107, 114, .1);
    color: #ff6b72
}

.ant-avatar-geekblue {
    background: rgba(23, 188, 255, .1);
    color: #17bcff
}

.ant-avatar-lime {
    background: rgba(160, 217, 17, .1);
    color: #a0d911
}

.ant-avatar-gold {
    background: rgba(255, 197, 66, .1);
    color: #ffc542
}

.ant-avatar-primary {
    background: rgba(62, 121, 247, .1);
    color: #3e79f7
}

.ant-avatar-secondary {
    background: rgba(164, 97, 216, .1);
    color: #a461d8
}

.ant-avatar-success {
    background: rgba(4, 209, 130, .1);
    color: #04d182
}

.ant-avatar-info {
    background: rgba(62, 121, 247, .1);
    color: #3e79f7
}

.ant-avatar-warning {
    background: rgba(255, 197, 66, .1);
    color: #ffc542
}

.ant-avatar-danger {
    background: rgba(255, 107, 114, .1);
    color: #ff6b72
}

.ant-avatar-gray {
    background: rgba(69, 85, 96, .1);
    color: #455560
}

.ant-avatar-gray-light {
    background: rgba(114, 132, 154, .1);
    color: #72849a
}

.ant-avatar-gray-lighter {
    background: rgba(237, 237, 237, .1);
    color: #ededed
}

.ant-avatar-gray-lightest {
    background: rgba(247, 247, 248, .1);
    color: #f7f7f8
}

.ant-avatar-dark {
    background: rgba(26, 51, 83, .1);
    color: #1a3353
}

.ant-card {
    margin-bottom: 20px
}

.ant-card-head {
    padding: 0 20px;
    margin-bottom: -1px;
    min-height: 35px !important;
    border-bottom: 0 !important;
    font-size: 17px !important
}

.ant-card-head .ant-tabs-bar {
    border-bottom: 1px solid #e6ebf1 !important
}

.ant-card-head .ant-tabs {
    margin-bottom: -21px !important
}

.ant-card-head-title {
    font-weight: 700;
    padding-bottom: 0;
    padding-top: 16px
}

.ant-card-extra {
    padding-top: 20px !important;
    padding-bottom: 0 !important
}

.ant-table-wrapper {
    overflow-x: auto
}

.ant-timeline .ant-timeline-item-head {
    border-style: solid
}

.ant-select-dropdown .ant-select-item {
    border-radius: 0 !important
}

.ant-rate {
    color: #ffc542
}

.ant-menu {
    font-weight: 500
}

.ant-menu-item-group-title {
    padding: 12px 24px !important;
    color: rgba(26, 51, 83, .6);
    font-size: 12px !important;
    line-height: 1.5;
    font-weight: 700;
    text-transform: uppercase;
    margin-top: 15px
}

.ant-menu-light .ant-menu-item-group-title {
    color: rgba(26, 51, 83, .6) !important
}

.ant-menu-inline-collapsed .ant-menu-item-group-title, .ql-snow .ql-toolbar input.ql-image[type=file], .ql-snow.ql-toolbar input.ql-image[type=file] {
    display: none
}

.apexcharts-tooltip {
    box-shadow: #aeaeae 0 0 10px !important
}

.apexcharts-tooltip.apexcharts-theme-light {
    border: 0 !important;
    background: #fff !important
}

.apexcharts-tooltip.apexcharts-theme-light .apexcharts-tooltip-title {
    background: 0 0 !important;
    border-bottom: 0 !important
}

.apexcharts-tooltip-title {
    margin-bottom: 0 !important
}

.apexcharts-gridline {
    stroke: #e6ebf1
}

.apexcharts-text tspan {
    fill: #455560
}

.apexcharts-menu-item {
    text-align: left
}

.apexcharts-xaxistooltip {
    opacity: 0;
    padding: 9px 10px;
    pointer-events: none;
    color: #373d3f;
    font-size: 13px;
    text-align: center;
    border-radius: 2px;
    position: absolute;
    z-index: 10;
    background: #eceff1;
    border: 1px solid #90a4ae;
    transition: .15s ease all
}

.apexcharts-legend-text {
    color: #455560 !important;
    font-size: 14px !important;
    font-weight: 400 !important;
    font-family: 'Roboto', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji' !important
}

.ql-container {
    box-sizing: border-box;
    font-size: 13px;
    height: 100%;
    margin: 0;
    position: relative
}

.ql-container.ql-disabled .ql-tooltip {
    visibility: hidden
}

.ql-container.ql-disabled .ql-editor ul[data-checked] > li::before, .ql-editor ul[data-checked=false], .ql-editor ul[data-checked=true] {
    pointer-events: none
}

.ql-clipboard {
    left: -100000px;
    height: 1px;
    overflow-y: hidden;
    position: absolute;
    top: 50%
}

.hl-code code, .ql-clipboard p {
    margin: 0;
    padding: 0
}

.ql-editor {
    box-sizing: border-box;
    line-height: 1.42;
    height: 100%;
    outline: 0;
    overflow-y: auto;
    padding: 12px 15px;
    tab-size: 4;
    -moz-tab-size: 4;
    text-align: left;
    white-space: pre-wrap;
    word-wrap: break-word
}

.ql-editor > * {
    cursor: text
}

.ql-editor blockquote, .ql-editor h1, .ql-editor h2, .ql-editor h3, .ql-editor h4, .ql-editor h5, .ql-editor h6, .ql-editor ol, .ql-editor p, .ql-editor pre, .ql-editor ul {
    margin: 0;
    padding: 0;
    counter-reset: list-1 list-2 list-3 list-4 list-5 list-6 list-7 list-8 list-9
}

.ql-editor ol, .ql-editor ul {
    padding-left: 1.5em
}

.ql-editor ol > li, .ql-editor ul > li {
    list-style-type: none
}

.ql-editor ul > li::before {
    content: '\2022'
}

.ql-editor ul[data-checked=false] > li *, .ql-editor ul[data-checked=true] > li * {
    pointer-events: all
}

.ql-editor ul[data-checked=false] > li::before, .ql-editor ul[data-checked=true] > li::before {
    color: #777;
    cursor: pointer;
    pointer-events: all
}

.ql-editor ul[data-checked=true] > li::before {
    content: '\2611'
}

.ql-editor ul[data-checked=false] > li::before {
    content: '\2610'
}

.ql-editor li::before {
    display: inline-block;
    white-space: nowrap;
    width: 1.2em
}

.ql-editor li:not(.ql-direction-rtl)::before {
    margin-left: -1.5em;
    margin-right: .3em;
    text-align: right
}

.ql-editor li.ql-direction-rtl::before {
    margin-left: .3em;
    margin-right: -1.5em
}

.ql-editor ol li:not(.ql-direction-rtl), .ql-editor ul li:not(.ql-direction-rtl) {
    padding-left: 1.5em
}

.ql-editor ol li.ql-direction-rtl, .ql-editor ul li.ql-direction-rtl {
    padding-right: 1.5em
}

.ql-editor ol li {
    counter-reset: list-1 list-2 list-3 list-4 list-5 list-6 list-7 list-8 list-9;
    counter-increment: list-0
}

.ql-editor ol li:before {
    content: counter(list-0, decimal) '. '
}

.ql-editor ol li.ql-indent-1 {
    counter-increment: list-1;
    counter-reset: list-2 list-3 list-4 list-5 list-6 list-7 list-8 list-9
}

.ql-editor ol li.ql-indent-1:before {
    content: counter(list-1, lower-alpha) '. '
}

.ql-editor ol li.ql-indent-2 {
    counter-increment: list-2;
    counter-reset: list-3 list-4 list-5 list-6 list-7 list-8 list-9
}

.ql-editor ol li.ql-indent-2:before {
    content: counter(list-2, lower-roman) '. '
}

.ql-editor ol li.ql-indent-3 {
    counter-increment: list-3;
    counter-reset: list-4 list-5 list-6 list-7 list-8 list-9
}

.ql-editor ol li.ql-indent-3:before {
    content: counter(list-3, decimal) '. '
}

.ql-editor ol li.ql-indent-4 {
    counter-increment: list-4;
    counter-reset: list-5 list-6 list-7 list-8 list-9
}

.ql-editor ol li.ql-indent-4:before {
    content: counter(list-4, lower-alpha) '. '
}

.ql-editor ol li.ql-indent-5 {
    counter-increment: list-5;
    counter-reset: list-6 list-7 list-8 list-9
}

.ql-editor ol li.ql-indent-5:before {
    content: counter(list-5, lower-roman) '. '
}

.ql-editor ol li.ql-indent-6 {
    counter-increment: list-6;
    counter-reset: list-7 list-8 list-9
}

.ql-editor ol li.ql-indent-6:before {
    content: counter(list-6, decimal) '. '
}

.ql-editor ol li.ql-indent-7 {
    counter-increment: list-7;
    counter-reset: list-8 list-9
}

.ql-editor ol li.ql-indent-7:before {
    content: counter(list-7, lower-alpha) '. '
}

.ql-editor ol li.ql-indent-8 {
    counter-increment: list-8;
    counter-reset: list-9
}

.ql-editor ol li.ql-indent-8:before {
    content: counter(list-8, lower-roman) '. '
}

.ql-editor ol li.ql-indent-9 {
    counter-increment: list-9
}

.ql-editor ol li.ql-indent-9:before {
    content: counter(list-9, decimal) '. '
}

.ql-editor .ql-indent-1:not(.ql-direction-rtl) {
    padding-left: 3em
}

.ql-editor li.ql-indent-1:not(.ql-direction-rtl) {
    padding-left: 4.5em
}

.ql-editor .ql-indent-1.ql-direction-rtl.ql-align-right {
    padding-right: 3em
}

.ql-editor li.ql-indent-1.ql-direction-rtl.ql-align-right {
    padding-right: 4.5em
}

.ql-editor .ql-indent-2:not(.ql-direction-rtl) {
    padding-left: 6em
}

.ql-editor li.ql-indent-2:not(.ql-direction-rtl) {
    padding-left: 7.5em
}

.ql-editor .ql-indent-2.ql-direction-rtl.ql-align-right {
    padding-right: 6em
}

.ql-editor li.ql-indent-2.ql-direction-rtl.ql-align-right {
    padding-right: 7.5em
}

.ql-editor .ql-indent-3:not(.ql-direction-rtl) {
    padding-left: 9em
}

.ql-editor li.ql-indent-3:not(.ql-direction-rtl) {
    padding-left: 10.5em
}

.ql-editor .ql-indent-3.ql-direction-rtl.ql-align-right {
    padding-right: 9em
}

.ql-editor li.ql-indent-3.ql-direction-rtl.ql-align-right {
    padding-right: 10.5em
}

.ql-editor .ql-indent-4:not(.ql-direction-rtl) {
    padding-left: 12em
}

.ql-editor li.ql-indent-4:not(.ql-direction-rtl) {
    padding-left: 13.5em
}

.ql-editor .ql-indent-4.ql-direction-rtl.ql-align-right {
    padding-right: 12em
}

.ql-editor li.ql-indent-4.ql-direction-rtl.ql-align-right {
    padding-right: 13.5em
}

.ql-editor .ql-indent-5:not(.ql-direction-rtl) {
    padding-left: 15em
}

.ql-editor li.ql-indent-5:not(.ql-direction-rtl) {
    padding-left: 16.5em
}

.ql-editor .ql-indent-5.ql-direction-rtl.ql-align-right {
    padding-right: 15em
}

.ql-editor li.ql-indent-5.ql-direction-rtl.ql-align-right {
    padding-right: 16.5em
}

.ql-editor .ql-indent-6:not(.ql-direction-rtl) {
    padding-left: 18em
}

.ql-editor li.ql-indent-6:not(.ql-direction-rtl) {
    padding-left: 19.5em
}

.ql-editor .ql-indent-6.ql-direction-rtl.ql-align-right {
    padding-right: 18em
}

.ql-editor li.ql-indent-6.ql-direction-rtl.ql-align-right {
    padding-right: 19.5em
}

.ql-editor .ql-indent-7:not(.ql-direction-rtl) {
    padding-left: 21em
}

.ql-editor li.ql-indent-7:not(.ql-direction-rtl) {
    padding-left: 22.5em
}

.ql-editor .ql-indent-7.ql-direction-rtl.ql-align-right {
    padding-right: 21em
}

.ql-editor li.ql-indent-7.ql-direction-rtl.ql-align-right {
    padding-right: 22.5em
}

.ql-editor .ql-indent-8:not(.ql-direction-rtl) {
    padding-left: 24em
}

.ql-editor li.ql-indent-8:not(.ql-direction-rtl) {
    padding-left: 25.5em
}

.ql-editor .ql-indent-8.ql-direction-rtl.ql-align-right {
    padding-right: 24em
}

.ql-editor li.ql-indent-8.ql-direction-rtl.ql-align-right {
    padding-right: 25.5em
}

.ql-editor .ql-indent-9:not(.ql-direction-rtl) {
    padding-left: 27em
}

.ql-editor li.ql-indent-9:not(.ql-direction-rtl) {
    padding-left: 28.5em
}

.ql-editor .ql-indent-9.ql-direction-rtl.ql-align-right {
    padding-right: 27em
}

.ql-editor li.ql-indent-9.ql-direction-rtl.ql-align-right {
    padding-right: 28.5em
}

.ql-editor .ql-video {
    display: block;
    max-width: 100%
}

.ql-editor .ql-video.ql-align-center {
    margin: 0 auto
}

.ql-editor .ql-video.ql-align-right {
    margin: 0 0 0 auto
}

.ql-editor .ql-bg-black {
    background-color: #000
}

.ql-editor .ql-bg-red {
    background-color: #e60000
}

.ql-editor .ql-bg-orange {
    background-color: #f90
}

.ql-editor .ql-bg-yellow {
    background-color: #ff0
}

.ql-editor .ql-bg-green {
    background-color: #008a00
}

.ql-editor .ql-bg-blue {
    background-color: #3e79f7
}

.ql-editor .ql-bg-purple {
    background-color: #93f
}

.ql-editor .ql-color-white {
    color: #fff
}

.ql-editor .ql-color-red {
    color: #e60000
}

.ql-editor .ql-color-orange {
    color: #f90
}

.ql-editor .ql-color-yellow {
    color: #ff0
}

.ql-editor .ql-color-green {
    color: #008a00
}

.ql-editor .ql-color-blue {
    color: #3e79f7
}

.ql-editor .ql-color-purple {
    color: #93f
}

.ql-editor .ql-font-serif {
    font-family: Georgia, Times New Roman, serif
}

.ql-editor .ql-font-monospace {
    font-family: Monaco, Courier New, monospace
}

.ql-editor .ql-size-small {
    font-size: .75em
}

.ql-editor .ql-size-large, .ql-snow .ql-editor h2 {
    font-size: 1.5em
}

.ql-editor .ql-size-huge {
    font-size: 2.5em
}

.ql-editor .ql-direction-rtl {
    direction: rtl;
    text-align: inherit
}

.ql-editor .ql-align-center {
    text-align: center
}

.ql-editor .ql-align-justify {
    text-align: justify
}

.ql-editor .ql-align-right {
    text-align: right
}

.ql-editor.ql-blank::before {
    color: rgba(114, 132, 154, .4);
    content: attr(data-placeholder);
    font-style: italic;
    left: 15px;
    pointer-events: none;
    position: absolute;
    right: 15px
}

.ql-snow .ql-formats:after, .ql-snow .ql-toolbar:after, .ql-snow.ql-toolbar:after {
    clear: both;
    content: '';
    display: table
}

.ql-snow .ql-toolbar button, .ql-snow.ql-toolbar button {
    background: 0 0;
    border: 0;
    cursor: pointer;
    display: inline-block;
    float: left;
    height: 26px;
    padding: 5px 7px;
    width: 30px;
    border-radius: 4px
}

.ql-snow .ql-toolbar button svg, .ql-snow.ql-toolbar button svg {
    float: left;
    height: 100%
}

.ql-snow .ql-toolbar button:active:hover, .ql-snow.ql-toolbar button:active:hover {
    outline: 0
}

.ql-snow .ql-toolbar .ql-picker-item.ql-selected, .ql-snow .ql-toolbar .ql-picker-item:hover, .ql-snow .ql-toolbar .ql-picker-label:hover, .ql-snow .ql-toolbar button:focus, .ql-snow .ql-toolbar button:hover, .ql-snow.ql-toolbar .ql-picker-item.ql-selected, .ql-snow.ql-toolbar .ql-picker-item:hover, .ql-snow.ql-toolbar .ql-picker-label:hover, .ql-snow.ql-toolbar button:focus, .ql-snow.ql-toolbar button:hover {
    color: #3e79f7;
    background-color: rgba(62, 121, 247, .15)
}

.ql-snow .ql-toolbar .ql-picker-label.ql-active, .ql-snow .ql-toolbar button.ql-active, .ql-snow a, .ql-snow.ql-toolbar .ql-picker-label.ql-active, .ql-snow.ql-toolbar button.ql-active {
    color: #3e79f7
}

.ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-fill, .ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-stroke.ql-fill, .ql-snow .ql-toolbar .ql-picker-item:hover .ql-fill, .ql-snow .ql-toolbar .ql-picker-item:hover .ql-stroke.ql-fill, .ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-fill, .ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-stroke.ql-fill, .ql-snow .ql-toolbar .ql-picker-label:hover .ql-fill, .ql-snow .ql-toolbar .ql-picker-label:hover .ql-stroke.ql-fill, .ql-snow .ql-toolbar button.ql-active .ql-fill, .ql-snow .ql-toolbar button.ql-active .ql-stroke.ql-fill, .ql-snow .ql-toolbar button:focus .ql-fill, .ql-snow .ql-toolbar button:focus .ql-stroke.ql-fill, .ql-snow .ql-toolbar button:hover .ql-fill, .ql-snow .ql-toolbar button:hover .ql-stroke.ql-fill, .ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-fill, .ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-stroke.ql-fill, .ql-snow.ql-toolbar .ql-picker-item:hover .ql-fill, .ql-snow.ql-toolbar .ql-picker-item:hover .ql-stroke.ql-fill, .ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-fill, .ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-stroke.ql-fill, .ql-snow.ql-toolbar .ql-picker-label:hover .ql-fill, .ql-snow.ql-toolbar .ql-picker-label:hover .ql-stroke.ql-fill, .ql-snow.ql-toolbar button.ql-active .ql-fill, .ql-snow.ql-toolbar button.ql-active .ql-stroke.ql-fill, .ql-snow.ql-toolbar button:focus .ql-fill, .ql-snow.ql-toolbar button:focus .ql-stroke.ql-fill, .ql-snow.ql-toolbar button:hover .ql-fill, .ql-snow.ql-toolbar button:hover .ql-stroke.ql-fill {
    fill: #3e79f7
}

.ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-stroke, .ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-stroke-miter, .ql-snow .ql-toolbar .ql-picker-item:hover .ql-stroke, .ql-snow .ql-toolbar .ql-picker-item:hover .ql-stroke-miter, .ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-stroke, .ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-stroke-miter, .ql-snow .ql-toolbar .ql-picker-label:hover .ql-stroke, .ql-snow .ql-toolbar .ql-picker-label:hover .ql-stroke-miter, .ql-snow .ql-toolbar button.ql-active .ql-stroke, .ql-snow .ql-toolbar button.ql-active .ql-stroke-miter, .ql-snow .ql-toolbar button:focus .ql-stroke, .ql-snow .ql-toolbar button:focus .ql-stroke-miter, .ql-snow .ql-toolbar button:hover .ql-stroke, .ql-snow .ql-toolbar button:hover .ql-stroke-miter, .ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-stroke, .ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-stroke-miter, .ql-snow.ql-toolbar .ql-picker-item:hover .ql-stroke, .ql-snow.ql-toolbar .ql-picker-item:hover .ql-stroke-miter, .ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-stroke, .ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-stroke-miter, .ql-snow.ql-toolbar .ql-picker-label:hover .ql-stroke, .ql-snow.ql-toolbar .ql-picker-label:hover .ql-stroke-miter, .ql-snow.ql-toolbar button.ql-active .ql-stroke, .ql-snow.ql-toolbar button.ql-active .ql-stroke-miter, .ql-snow.ql-toolbar button:focus .ql-stroke, .ql-snow.ql-toolbar button:focus .ql-stroke-miter, .ql-snow.ql-toolbar button:hover .ql-stroke, .ql-snow.ql-toolbar button:hover .ql-stroke-miter {
    stroke: #3e79f7
}

@media (pointer: coarse) {
    .ql-snow .ql-toolbar button:hover:not(.ql-active), .ql-snow.ql-toolbar button:hover:not(.ql-active) {
        color: #455560
    }

    .ql-snow .ql-toolbar button:hover:not(.ql-active) .ql-fill, .ql-snow .ql-toolbar button:hover:not(.ql-active) .ql-stroke.ql-fill, .ql-snow.ql-toolbar button:hover:not(.ql-active) .ql-fill, .ql-snow.ql-toolbar button:hover:not(.ql-active) .ql-stroke.ql-fill {
        fill: #455560
    }

    .ql-snow .ql-toolbar button:hover:not(.ql-active) .ql-stroke, .ql-snow .ql-toolbar button:hover:not(.ql-active) .ql-stroke-miter, .ql-snow.ql-toolbar button:hover:not(.ql-active) .ql-stroke, .ql-snow.ql-toolbar button:hover:not(.ql-active) .ql-stroke-miter {
        stroke: #455560
    }
}

.ql-snow, .ql-snow * {
    box-sizing: border-box
}

.ql-snow .ql-hidden, .ql-snow .ql-tooltip.ql-editing a.ql-preview, .ql-snow .ql-tooltip.ql-editing a.ql-remove {
    display: none
}

.ql-snow .ql-out-bottom, .ql-snow .ql-out-top {
    visibility: hidden
}

.ql-snow .ql-tooltip {
    position: absolute;
    -webkit-transform: translateY(10px);
    transform: translateY(10px)
}

.ql-snow .ql-tooltip a {
    cursor: pointer;
    text-decoration: none
}

.ql-snow .ql-tooltip.ql-flip {
    -webkit-transform: translateY(-10px);
    transform: translateY(-10px)
}

.ql-snow .ql-formats {
    display: inline-block;
    vertical-align: middle
}

.ql-snow .ql-stroke {
    fill: none;
    stroke: #455560;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-width: 2
}

.ql-snow .ql-stroke-miter {
    fill: none;
    stroke: #455560;
    stroke-miterlimit: 10;
    stroke-width: 2
}

.ql-snow .ql-fill, .ql-snow .ql-stroke.ql-fill {
    fill: #455560
}

.ql-snow .ql-empty {
    fill: none
}

.ql-snow .ql-even {
    fill-rule: evenodd
}

.ql-snow .ql-stroke.ql-thin, .ql-snow .ql-thin {
    stroke-width: 1
}

.ql-snow .ql-transparent {
    opacity: .4
}

.ql-snow .ql-direction svg:last-child {
    display: none
}

.ql-snow .ql-direction.ql-active svg:last-child {
    display: inline
}

.ql-snow .ql-direction.ql-active svg:first-child {
    display: none
}

.ql-snow .ql-editor h1 {
    font-size: 2em
}

.ql-snow .ql-editor h3 {
    font-size: 1.17em
}

.ql-snow .ql-editor h4 {
    font-size: 1em
}

.ql-snow .ql-editor h5 {
    font-size: .83em
}

.ql-snow .ql-editor h6 {
    font-size: .67em
}

.ql-snow .ql-editor a {
    text-decoration: underline
}

.ql-snow .ql-editor blockquote {
    border-left: 4px solid #ccc;
    margin-bottom: 5px;
    margin-top: 5px;
    padding-left: 16px
}

.ql-snow .ql-editor code, .ql-snow .ql-editor pre {
    background-color: #f0f0f0;
    border-radius: 3px
}

.ql-snow .ql-editor pre {
    white-space: pre-wrap;
    margin-bottom: 5px;
    margin-top: 5px;
    padding: 5px 10px
}

.ql-snow .ql-editor code {
    font-size: 85%;
    padding: 2px 4px
}

.ql-snow .ql-editor pre.ql-syntax {
    background-color: #23241f;
    color: #f8f8f2;
    overflow: visible
}

.ql-snow .ql-editor img {
    max-width: 100%
}

.ql-snow .ql-picker {
    color: #455560;
    display: inline-block;
    float: left;
    font-size: 14px;
    font-weight: 500;
    height: 24px;
    position: relative;
    vertical-align: middle
}

.ql-snow .ql-picker-label {
    cursor: pointer;
    display: inline-block;
    height: 100%;
    padding-left: 8px;
    padding-right: 2px;
    position: relative;
    width: 100%
}

.ql-snow .ql-picker-label::before {
    display: inline-block;
    line-height: 22px
}

.ql-snow .ql-picker-options {
    background-color: #fff;
    display: none;
    min-width: 100%;
    padding: 4px 8px;
    position: absolute;
    white-space: nowrap
}

.ql-snow .ql-picker-options .ql-picker-item {
    cursor: pointer;
    display: block;
    padding: 5px 10px
}

.ql-snow .ql-picker.ql-expanded .ql-picker-label {
    color: #ccc;
    z-index: 2
}

.ql-snow .ql-picker.ql-expanded .ql-picker-label .ql-fill {
    fill: #ccc
}

.ql-snow .ql-picker.ql-expanded .ql-picker-label .ql-stroke {
    stroke: #ccc
}

.ql-snow .ql-picker.ql-expanded .ql-picker-options {
    display: block;
    margin-top: -1px;
    top: 100%;
    z-index: 1
}

.ql-snow .ql-color-picker, .ql-snow .ql-icon-picker {
    width: 28px
}

.ql-snow .ql-color-picker .ql-picker-label, .ql-snow .ql-icon-picker .ql-picker-label {
    padding: 2px 4px
}

.ql-snow .ql-color-picker .ql-picker-label svg, .ql-snow .ql-icon-picker .ql-picker-label svg {
    right: 4px
}

.ql-snow .ql-icon-picker .ql-picker-options {
    padding: 4px 0
}

.ql-snow .ql-icon-picker .ql-picker-item {
    height: 24px;
    width: 24px;
    padding: 2px 4px
}

.ql-snow .ql-color-picker .ql-picker-options {
    padding: 3px 5px;
    width: 152px
}

.ql-snow .ql-color-picker .ql-picker-item {
    border: 1px solid transparent;
    float: left;
    height: 16px;
    margin: 2px;
    padding: 0;
    width: 16px
}

.ql-snow .ql-picker:not(.ql-color-picker):not(.ql-icon-picker) svg {
    position: absolute;
    margin-top: -9px;
    right: 0;
    top: 50%;
    width: 18px
}

.ql-snow .ql-picker.ql-font .ql-picker-item[data-label]:not([data-label=''])::before, .ql-snow .ql-picker.ql-font .ql-picker-label[data-label]:not([data-label=''])::before, .ql-snow .ql-picker.ql-header .ql-picker-item[data-label]:not([data-label=''])::before, .ql-snow .ql-picker.ql-header .ql-picker-label[data-label]:not([data-label=''])::before, .ql-snow .ql-picker.ql-size .ql-picker-item[data-label]:not([data-label=''])::before, .ql-snow .ql-picker.ql-size .ql-picker-label[data-label]:not([data-label=''])::before {
    content: attr(data-label)
}

.ql-snow .ql-picker.ql-header {
    width: 98px
}

.ql-snow .ql-picker.ql-header .ql-picker-item::before, .ql-snow .ql-picker.ql-header .ql-picker-label::before {
    content: 'Normal'
}

.ql-snow .ql-picker.ql-header .ql-picker-item[data-value="1"]::before, .ql-snow .ql-picker.ql-header .ql-picker-label[data-value="1"]::before {
    content: 'Heading 1'
}

.ql-snow .ql-picker.ql-header .ql-picker-item[data-value="2"]::before, .ql-snow .ql-picker.ql-header .ql-picker-label[data-value="2"]::before {
    content: 'Heading 2'
}

.ql-snow .ql-picker.ql-header .ql-picker-item[data-value="3"]::before, .ql-snow .ql-picker.ql-header .ql-picker-label[data-value="3"]::before {
    content: 'Heading 3'
}

.ql-snow .ql-picker.ql-header .ql-picker-item[data-value="4"]::before, .ql-snow .ql-picker.ql-header .ql-picker-label[data-value="4"]::before {
    content: 'Heading 4'
}

.ql-snow .ql-picker.ql-header .ql-picker-item[data-value="5"]::before, .ql-snow .ql-picker.ql-header .ql-picker-label[data-value="5"]::before {
    content: 'Heading 5'
}

.ql-snow .ql-picker.ql-header .ql-picker-item[data-value="6"]::before, .ql-snow .ql-picker.ql-header .ql-picker-label[data-value="6"]::before {
    content: 'Heading 6'
}

.ql-snow .ql-picker.ql-header .ql-picker-item[data-value="1"]::before {
    font-size: 2em
}

.ql-snow .ql-picker.ql-header .ql-picker-item[data-value="2"]::before {
    font-size: 1.5em
}

.ql-snow .ql-picker.ql-header .ql-picker-item[data-value="3"]::before {
    font-size: 1.17em
}

.ql-snow .ql-picker.ql-header .ql-picker-item[data-value="4"]::before {
    font-size: 1em
}

.ql-snow .ql-picker.ql-header .ql-picker-item[data-value="5"]::before {
    font-size: .83em
}

.ql-snow .ql-picker.ql-header .ql-picker-item[data-value="6"]::before {
    font-size: .67em
}

.ql-snow .ql-picker.ql-font {
    width: 108px
}

.ql-snow .ql-picker.ql-font .ql-picker-item::before, .ql-snow .ql-picker.ql-font .ql-picker-label::before {
    content: 'Sans Serif'
}

.ql-snow .ql-picker.ql-font .ql-picker-item[data-value=serif]::before, .ql-snow .ql-picker.ql-font .ql-picker-label[data-value=serif]::before {
    content: 'Serif'
}

.ql-snow .ql-picker.ql-font .ql-picker-item[data-value=monospace]::before, .ql-snow .ql-picker.ql-font .ql-picker-label[data-value=monospace]::before {
    content: 'Monospace'
}

.ql-snow .ql-picker.ql-font .ql-picker-item[data-value=serif]::before {
    font-family: Georgia, Times New Roman, serif
}

.ql-snow .ql-picker.ql-font .ql-picker-item[data-value=monospace]::before {
    font-family: Monaco, Courier New, monospace
}

.ql-snow .ql-picker.ql-size {
    width: 98px
}

.ql-snow .ql-picker.ql-size .ql-picker-item::before, .ql-snow .ql-picker.ql-size .ql-picker-label::before {
    content: 'Normal'
}

.ql-snow .ql-picker.ql-size .ql-picker-item[data-value=small]::before, .ql-snow .ql-picker.ql-size .ql-picker-label[data-value=small]::before {
    content: 'Small'
}

.ql-snow .ql-picker.ql-size .ql-picker-item[data-value=large]::before, .ql-snow .ql-picker.ql-size .ql-picker-label[data-value=large]::before {
    content: 'Large'
}

.ql-snow .ql-picker.ql-size .ql-picker-item[data-value=huge]::before, .ql-snow .ql-picker.ql-size .ql-picker-label[data-value=huge]::before {
    content: 'Huge'
}

.ql-snow .ql-picker.ql-size .ql-picker-item[data-value=small]::before {
    font-size: 10px
}

.ql-snow .ql-picker.ql-size .ql-picker-item[data-value=large]::before {
    font-size: 18px
}

.ql-snow .ql-picker.ql-size .ql-picker-item[data-value=huge]::before {
    font-size: 32px
}

.ql-snow .ql-color-picker.ql-background .ql-picker-item {
    background-color: #fff
}

.ql-snow .ql-color-picker.ql-color .ql-picker-item {
    background-color: #000
}

.ql-toolbar.ql-snow {
    border: 1px solid #e6ebf1;
    box-sizing: border-box;
    font-family: 'Helvetica Neue', 'Helvetica', 'Arial', sans-serif;
    padding: 8px
}

.ql-toolbar.ql-snow .ql-formats {
    margin-right: 15px
}

.ql-toolbar.ql-snow .ql-picker-label {
    border: 1px solid transparent
}

.ql-toolbar.ql-snow .ql-picker-options {
    border: 1px solid transparent;
    box-shadow: rgba(0, 0, 0, .2) 0 2px 8px
}

.ql-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-label, .ql-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-options {
    border-color: #e6ebf1
}

.ql-toolbar.ql-snow .ql-color-picker .ql-picker-item.ql-selected, .ql-toolbar.ql-snow .ql-color-picker .ql-picker-item:hover {
    border-color: #000
}

.ql-toolbar.ql-snow + .ql-container.ql-snow {
    border-top: 0
}

.ql-snow .ql-tooltip {
    background-color: #fff;
    box-shadow: 0 0 5px #ddd;
    color: #455560;
    padding: 5px 12px;
    white-space: nowrap
}

.ql-snow .ql-tooltip::before {
    content: "Visit URL:";
    line-height: 26px;
    margin-right: 8px
}

.ql-snow .ql-tooltip input[type=text] {
    display: none;
    border: 1px solid #e6ebf1;
    font-size: 13px;
    height: 26px;
    margin: 0;
    padding: 3px 5px;
    width: 170px
}

.ql-snow .ql-tooltip a.ql-preview {
    display: inline-block;
    max-width: 200px;
    overflow-x: hidden;
    text-overflow: ellipsis;
    vertical-align: top
}

.ql-snow .ql-tooltip a.ql-action::after {
    border-right: 1px solid #e6ebf1;
    content: 'Edit';
    margin-left: 16px;
    padding-right: 8px
}

.ql-snow .ql-tooltip a.ql-remove::before {
    content: 'Remove';
    margin-left: 8px
}

.ql-snow .ql-tooltip a {
    line-height: 26px
}

.ql-snow .ql-tooltip.ql-editing input[type=text] {
    display: inline-block
}

.ql-snow .ql-tooltip.ql-editing a.ql-action::after {
    border-right: 0;
    content: 'Save';
    padding-right: 0
}

.ql-snow .ql-tooltip[data-mode=link]::before {
    content: "Enter link:"
}

.ql-snow .ql-tooltip[data-mode=formula]::before {
    content: "Enter formula:"
}

.ql-snow .ql-tooltip[data-mode=video]::before {
    content: "Enter video:"
}

.ql-container.ql-snow, .ql-snow .ql-tooltip {
    border: 1px solid #e6ebf1
}

.chat .chat-content {
    height: 100%
}

.chat .chat-content .chat-content-header {
    height: 60px;
    border-bottom: 1px solid #e6ebf1;
    padding: 0 15px;
    display: flex;
    align-items: center;
    justify-content: space-between
}

.chat .chat-content .chat-content-body {
    height: calc(100vh - 60px - 76px - 25px - 70px - 60px - 30px);
    padding: 15px;
    overflow-y: auto
}

.chat .chat-content .chat-content-body .msg {
    display: flex;
    margin-bottom: 15px
}

.chat .chat-content .chat-content-body .msg .bubble {
    max-width: 75%;
    position: relative
}

.chat .chat-content .chat-content-body .msg .bubble .bubble-wrapper {
    padding: 10px 15px;
    border-radius: .625rem;
    overflow: hidden;
    border-radius: 10px;
    margin-bottom: 10px
}

.chat .chat-content .chat-content-body .msg.datetime {
    padding: 15px 0;
    margin-bottom: 0;
    font-size: 11px;
    font-weight: 500;
    justify-content: center
}

.chat .chat-content .chat-content-body .msg.datetime .ant-divider-inner-text {
    font-size: 12px
}

.chat .chat-content .chat-content-body .msg .file .anticon {
    margin-right: 10px;
    font-size: 17px
}

.chat .chat-content .chat-content-body .msg.msg-recipient .bubble .bubble-wrapper {
    background: #ededed;
    color: #1a3353
}

.chat .chat-content .chat-content-body .msg.msg-recipient .bubble .bubble-wrapper img {
    max-width: 250px;
    cursor: pointer
}

.chat .chat-content .chat-content-body .msg.msg-sent {
    justify-content: flex-end
}

.chat .chat-content .chat-content-body .msg.msg-sent .bubble .bubble-wrapper {
    background-color: #3e79f7;
    color: #fff
}

.chat .chat-content .chat-content-footer {
    height: 76px;
    padding: 0 20px;
    display: flex;
    align-items: center
}

.chat .chat-content-empty {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center
}

.chat .chat-content-empty img {
    max-width: 640px;
    width: 100%
}

.chat-menu {
    width: 100%
}

.chat-menu .chat-menu-toolbar {
    height: 60px;
    border-bottom: 1px solid #e6ebf1;
    padding: 0;
    display: flex;
    align-items: center
}

.chat-menu .chat-menu-toolbar .ant-input-affix-wrapper {
    border: 0
}

.chat-menu .chat-menu-toolbar .ant-input-affix-wrapper .ant-input-prefix {
    display: flex;
    align-items: center;
    color: rgba(114, 132, 154, .4)
}

.chat-menu .chat-menu-toolbar .ant-input-affix-wrapper.ant-input-affix-wrapper-focused {
    box-shadow: none
}

.chat-menu .chat-menu-toolbar .ant-input-affix-wrapper .ant-input {
    font-size: 21px
}

.chat-menu .chat-menu-toolbar .ant-input-affix-wrapper .ant-input .ant-input-focused, .chat-menu .chat-menu-toolbar .ant-input-affix-wrapper .ant-input:focus {
    box-shadow: none
}

.chat-menu .chat-menu-list {
    height: calc(100vh - 60px - 76px - 25px - 70px - 30px);
    overflow: auto;
    position: relative
}

.chat-menu .chat-menu-list .chat-menu-list-item {
    transition: all .3s ease-in-out;
    padding: 13px 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer
}

.chat-menu .chat-menu-list .chat-menu-list-item .chat-menu-list-item-time {
    font-size: 12px;
    color: rgba(114, 132, 154, .7);
    font-weight: 500
}

.chat-menu .chat-menu-list .chat-menu-list-item:not(.last) {
    border-bottom: 1px solid #e6ebf1
}

.chat-menu .chat-menu-list .chat-menu-list-item.selected, .chat-menu .chat-menu-list .chat-menu-list-item:hover {
    background-color: #f5f5f5
}

.chat-menu .chat-menu-list .chat-menu-list-item .avatar-status .avatar-status-subtitle {
    width: 166px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap
}

.mail .mail-list .ant-table-row {
    cursor: pointer
}

.mail .mail-list .ant-table-selection {
    padding: .3rem 0
}

.mail .mail-list .mail-list-star {
    padding-right: 15px;
    padding-left: 0;
    cursor: pointer
}

.mail .mail-list .mail-list-star.checked {
    color: #ffc542
}

.mail .mail-list .mail-list-star.uncheck {
    color: rgba(114, 132, 154, .7)
}

.mail .mail-detail .mail-detail-action-icon.star.checked, .mail .mail-detail .mail-detail-action-icon.star:hover, .mail .mail-list .mail-list-star:hover {
    color: #ffc542
}

.mail .mail-list .mail-list-sender {
    width: 220px;
    padding: 15px 0
}

.mail .mail-list .mail-list-content {
    max-width: 0
}

.mail .mail-list .mail-list-content .mail-list-content-msg {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap
}

.mail .mail-list .mail-list-date {
    width: 100px;
    text-align: right
}

.mail .mail-list .mail-list-action {
    display: flex;
    justify-content: space-between;
    align-items: center
}

.mail .mail-list .mail-list-action-icon {
    font-size: 21px;
    color: #455560;
    cursor: pointer;
    margin-left: 1.2rem
}

.mail .mail-detail .mail-detail-action {
    display: flex;
    align-items: center
}

.mail .mail-detail .mail-detail-action-icon {
    margin-left: .75rem;
    font-size: 21px;
    cursor: pointer
}

.mail .mail-detail .mail-detail-action-icon:hover {
    color: #3e79f7
}

.mail .mail-detail .mail-detail-content {
    margin-top: 1.25rem
}

.mail .mail-detail .mail-detail-attactment {
    display: flex;
    margin-top: 30px
}

.mail .mail-detail .mail-detail-attactment .mail-detail-attactment-item {
    cursor: pointer;
    border-radius: .3rem;
    border: 1px solid #e6ebf1;
    padding: 5px 10px;
    margin-right: 15px;
    display: flex;
    align-items: center
}

.mail .mail-detail .mail-detail-attactment .mail-detail-attactment-item .anticon {
    font-size: 2rem
}

.mail .mail-compose .ql-container {
    min-height: 9.5rem;
    border-top: 1px solid #e6ebf1 !important;
    border-bottom: 0
}

.mail .mail-compose .ql-editor {
    min-height: 9.5rem
}

.mail .mail-compose .editor-wrapper {
    position: relative
}

.mail .mail-compose .ql-toolbar {
    position: absolute;
    bottom: 0;
    width: 100%;
    -webkit-transform: translateY(100%);
    transform: translateY(100%);
    z-index: 1
}

.calendar .calendar-event {
    list-style: none;
    margin: 0;
    padding: 0
}

.calendar .calendar-event li {
    margin-bottom: 5px
}

@media only screen and (max-width: 992px) {
    .calendar .calendar-event li {
        display: inline-block
    }

    .calendar .calendar-event li .ant-badge {
        margin-right: 5px
    }

    .calendar .calendar-event li .ant-badge-status-text {
        display: none
    }
}

.calendar .calendar-list {
    margin-bottom: 2rem
}

.calendar .calendar-list .calendar-list-item {
    padding: 5px 10px;
    border-radius: .625rem;
    margin-bottom: 15px;
    display: flex;
    margin-left: 20px;
    background-color: #f7f7f8;
    align-items: center;
    justify-content: space-between;
    border: 1px solid #e6ebf1
}

@media only screen and (min-width: 768px) {
    .calendar .calendar-list .calendar-list-item {
        max-width: 75%
    }
}

.calendar .calendar-list .calendar-list-item .calendar-list-item-delete {
    opacity: 1;
    cursor: pointer;
    font-size: 17px;
    transition: all .1s ease-in-out
}

.calendar .calendar-list .calendar-list-item .calendar-list-item-delete:hover {
    color: #ff6b72
}

.scrumboard {
    width: 100%;
    flex: 1 1 auto;
    flex-direction: column;
    display: flex;
    height: calc(100vh - 130px - 25px - 25px)
}

.scrumboard .scrumboard-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 16px;
    margin-bottom: 16px
}

.scrumboard .scrumboard-body {
    display: flex;
    flex: 1 1;
    overflow-x: auto
}

.scrumboard .scrumboard-body > div {
    display: flex;
    flex: 1 1
}

.board-column, .board-column.add .board-title {
    border: 1px solid #e6ebf1;
    background-color: #f0f2f5;
    border-radius: .625rem
}

.board-column {
    display: flex;
    flex-direction: column;
    min-width: 300px;
    margin: 0 8px 15px
}

.board-column.add {
    border: 0;
    background-color: transparent
}

.board-column.add .board-title {
    justify-content: center;
    cursor: pointer
}

.board-column.add .board-title:hover {
    background-color: #eaedf1
}

.board-title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #e6ebf1;
    transition: background-color .2s ease;
    padding: .625rem .9375rem;
    border-top-left-radius: .625rem;
    border-top-right-radius: .625rem
}

.board-wrapper {
    overflow-y: auto;
    flex: 1 1 auto
}

.board-card {
    margin-bottom: 0
}

.board-card .ant-card-body {
    padding: .625rem
}

.board-add {
    background-color: #fff;
    justify-content: center;
    height: 2.8rem;
    display: flex;
    align-items: center;
    border-bottom-left-radius: .625rem;
    border-bottom-right-radius: .625rem;
    border-top: 1px solid #e6ebf1;
    font-weight: 500;
    cursor: pointer;
    transition: all .15s ease
}

.board-add:hover {
    background-color: #fcfcfc
}

.board-label {
    display: inline-block;
    width: 2rem;
    height: .1875rem;
    margin-right: .5rem
}

.board-label.blue {
    background: #3e79f7
}

.board-label.purple {
    background: #a461d8
}

.board-label.cyan {
    background: #04d182
}

.board-label.green {
    background: #21b573
}

.board-label.magenta, .board-label.pink {
    background: #eb2f96
}

.board-label.red {
    background: #de4436
}

.board-label.orange {
    background: #fa8c16
}

.board-label.yellow {
    background: #fadb14
}

.board-label.volcano {
    background: #ff6b72
}

.board-label.geekblue {
    background: #17bcff
}

.board-label.lime {
    background: #a0d911
}

.board-label.gold {
    background: #ffc542
}

.board-container {
    min-width: 100vw;
    display: inline-flex
}

.board-dropzone {
    padding: 0 .9375rem;
    padding-top: .9375rem;
    height: 100%
}

.board-card-modal.input {
    font-size: 16px;
    font-weight: 500;
    border-color: transparent;
    max-width: 95%;
    padding: 5px 7.5px;
    margin-left: -7.5px;
    box-shadow: none;
    color: #1a3353
}

.board-card-modal.input:focus {
    border-color: #3e79f7
}

.board-card-modal.text-area {
    border-color: transparent;
    margin-left: -8.5px
}

.board-card-modal.date-picker.ant-picker .ant-picker-focused, .board-card-modal.date-picker.ant-picker.ant-picker-focused, .board-card-modal.date-picker.ant-picker:hover, .board-card-modal.text-area:hover {
    border-color: #3e79f7
}

.board-card-modal.text-area:focus {
    border-color: #3e79f7
}

.board-card-modal.date-picker, .board-card-modal.select {
    margin-left: -7.5px
}

.board-card-modal.select .ant-select-selector {
    border-color: transparent;
    min-height: 2.45rem
}

.board-card-modal.date-picker.ant-picker {
    border-color: transparent;
    margin-left: -11px
}

.board-card-modal.date-picker.ant-picker .anticon-calendar {
    display: none
}

.blockform-col .ant-form-item-label {
    padding-bottom: 0
}

.blockform-col.col-3 {
    width: 33%;
    min-width: 235px;
    display: inline-block
}

.align-baseline {
    vertical-align: baseline !important
}

.align-top {
    vertical-align: top !important
}

.align-middle {
    vertical-align: middle !important
}

.align-bottom {
    vertical-align: bottom !important
}

.align-text-bottom {
    vertical-align: text-bottom !important
}

.align-text-top {
    vertical-align: text-top !important
}

.bg-primary {
    background-color: #3e79f7 !important
}

a.bg-primary:focus, a.bg-primary:hover, button.bg-primary:focus, button.bg-primary:hover {
    background-color: #0d57f5 !important
}

.bg-secondary {
    background-color: #a461d8 !important
}

a.bg-secondary:focus, a.bg-secondary:hover, button.bg-secondary:focus, button.bg-secondary:hover {
    background-color: #8c38ce !important
}

.bg-success {
    background-color: #04d182 !important
}

a.bg-success:focus, a.bg-success:hover, button.bg-success:focus, button.bg-success:hover {
    background-color: #039f63 !important
}

.bg-info {
    background-color: #3e79f7 !important
}

a.bg-info:focus, a.bg-info:hover, button.bg-info:focus, button.bg-info:hover {
    background-color: #0d57f5 !important
}

.bg-warning {
    background-color: #ffc542 !important
}

a.bg-warning:focus, a.bg-warning:hover, button.bg-warning:focus, button.bg-warning:hover {
    background-color: #ffb50f !important
}

.bg-danger {
    background-color: #ff6b72 !important
}

a.bg-danger:focus, a.bg-danger:hover, button.bg-danger:focus, button.bg-danger:hover {
    background-color: #ff3841 !important
}

.bg-gray {
    background-color: #455560 !important
}

a.bg-gray:focus, a.bg-gray:hover, button.bg-gray:focus, button.bg-gray:hover {
    background-color: #303b42 !important
}

.bg-gray-light {
    background-color: #72849a !important
}

a.bg-gray-light:focus, a.bg-gray-light:hover, button.bg-gray-light:focus, button.bg-gray-light:hover {
    background-color: #5b6b7e !important
}

.bg-gray-lighter {
    background-color: #ededed !important
}

a.bg-gray-lighter:focus, a.bg-gray-lighter:hover, button.bg-gray-lighter:focus, button.bg-gray-lighter:hover {
    background-color: #d4d4d4 !important
}

.bg-gray-lightest {
    background-color: #f7f7f8 !important
}

a.bg-gray-lightest:focus, a.bg-gray-lightest:hover, button.bg-gray-lightest:focus, button.bg-gray-lightest:hover {
    background-color: #dcdce0 !important
}

.bg-dark {
    background-color: #1a3353 !important
}

a.bg-dark:focus, a.bg-dark:hover, button.bg-dark:focus, button.bg-dark:hover {
    background-color: #0e1b2c !important
}

.bg-white {
    background-color: #fff !important
}

.bg-transparent {
    background-color: transparent !important
}

.border {
    border: 1px solid #e6ebf1 !important
}

.border-top {
    border-top: 1px solid #e6ebf1 !important
}

.border-bottom {
    border-bottom: 1px solid #e6ebf1 !important
}

.dir-ltr .border-right {
    border-right: 1px solid #e6ebf1 !important
}

.dir-ltr .border-left, .dir-rtl .border-right {
    border-left: 1px solid #e6ebf1 !important
}

.dir-rtl .border-left {
    border-right: 1px solid #e6ebf1 !important
}

.border-0 {
    border: 0 !important
}

.border-top-0 {
    border-top: 0 !important
}

.border-right-0 {
    border-right: 0 !important
}

.border-bottom-0 {
    border-bottom: 0 !important
}

.border-left-0 {
    border-left: 0 !important
}

.border-white {
    border-color: #fff !important
}

.rounded-sm {
    border-radius: .25rem !important
}

.rounded {
    border-radius: .625rem !important
}

.rounded-right, .rounded-top {
    border-top-right-radius: .625rem !important
}

.rounded-top {
    border-top-left-radius: .625rem !important
}

.rounded-right {
    border-bottom-right-radius: .625rem !important
}

.rounded-bottom, .rounded-left {
    border-bottom-left-radius: .625rem !important
}

.rounded-bottom {
    border-bottom-right-radius: .625rem !important
}

.rounded-left {
    border-top-left-radius: .625rem !important
}

.rounded-lg {
    border-radius: .3rem !important
}

.rounded-circle {
    border-radius: 50% !important
}

.rounded-pill {
    border-radius: 50rem !important
}

.rounded-0 {
    border-radius: 0 !important
}

.clearfix::after {
    clear: both
}

.cursor-pointer {
    cursor: pointer
}

.cursor-help {
    cursor: help
}

.cursor-copy {
    cursor: copy
}

.cursor-grab {
    cursor: grab
}

.cursor-text {
    cursor: text
}

.cursor-not-allowed {
    cursor: not-allowed
}

.d-none {
    display: none !important
}

.d-inline {
    display: inline !important
}

.d-inline-block {
    display: inline-block !important
}

.d-block {
    display: block !important
}

.d-table {
    display: table !important
}

.d-table-row {
    display: table-row !important
}

.d-table-cell {
    display: table-cell !important
}

.d-flex {
    display: flex !important
}

.d-inline-flex {
    display: inline-flex !important
}

@media (min-width: 576px) {
    .d-sm-none {
        display: none !important
    }

    .d-sm-inline {
        display: inline !important
    }

    .d-sm-inline-block {
        display: inline-block !important
    }

    .d-sm-block {
        display: block !important
    }

    .d-sm-table {
        display: table !important
    }

    .d-sm-table-row {
        display: table-row !important
    }

    .d-sm-table-cell {
        display: table-cell !important
    }

    .d-sm-flex {
        display: flex !important
    }

    .d-sm-inline-flex {
        display: inline-flex !important
    }
}

@media (min-width: 768px) {
    .d-md-none {
        display: none !important
    }

    .d-md-inline {
        display: inline !important
    }

    .d-md-inline-block {
        display: inline-block !important
    }

    .d-md-block {
        display: block !important
    }

    .d-md-table {
        display: table !important
    }

    .d-md-table-row {
        display: table-row !important
    }

    .d-md-table-cell {
        display: table-cell !important
    }

    .d-md-flex {
        display: flex !important
    }

    .d-md-inline-flex {
        display: inline-flex !important
    }
}

@media (min-width: 992px) {
    .d-lg-none {
        display: none !important
    }

    .d-lg-inline {
        display: inline !important
    }

    .d-lg-inline-block {
        display: inline-block !important
    }

    .d-lg-block {
        display: block !important
    }

    .d-lg-table {
        display: table !important
    }

    .d-lg-table-row {
        display: table-row !important
    }

    .d-lg-table-cell {
        display: table-cell !important
    }

    .d-lg-flex {
        display: flex !important
    }

    .d-lg-inline-flex {
        display: inline-flex !important
    }
}

@media (min-width: 1400px) {
    .d-xl-none {
        display: none !important
    }

    .d-xl-inline {
        display: inline !important
    }

    .d-xl-inline-block {
        display: inline-block !important
    }

    .d-xl-block {
        display: block !important
    }

    .d-xl-table {
        display: table !important
    }

    .d-xl-table-row {
        display: table-row !important
    }

    .d-xl-table-cell {
        display: table-cell !important
    }

    .d-xl-flex {
        display: flex !important
    }

    .d-xl-inline-flex {
        display: inline-flex !important
    }
}

@media print {
    .d-print-none {
        display: none !important
    }

    .d-print-inline {
        display: inline !important
    }

    .d-print-inline-block {
        display: inline-block !important
    }

    .d-print-block {
        display: block !important
    }

    .d-print-table {
        display: table !important
    }

    .d-print-table-row {
        display: table-row !important
    }

    .d-print-table-cell {
        display: table-cell !important
    }

    .d-print-flex {
        display: flex !important
    }

    .d-print-inline-flex {
        display: inline-flex !important
    }
}

.embed-responsive {
    position: relative;
    display: block;
    width: 100%;
    padding: 0;
    overflow: hidden
}

.clearfix::after, .embed-responsive::before {
    display: block;
    content: ""
}

.embed-responsive .embed-responsive-item, .embed-responsive embed, .embed-responsive iframe, .embed-responsive object, .embed-responsive video {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0
}

.embed-responsive-21by9::before {
    padding-top: 42.85714286%
}

.embed-responsive-16by9::before {
    padding-top: 56.25%
}

.embed-responsive-4by3::before {
    padding-top: 75%
}

.embed-responsive-1by1::before {
    padding-top: 100%
}

.flex-row {
    flex-direction: row !important
}

.flex-column {
    flex-direction: column !important
}

.flex-row-reverse {
    flex-direction: row-reverse !important
}

.flex-column-reverse {
    flex-direction: column-reverse !important
}

.flex-wrap {
    flex-wrap: wrap !important
}

.flex-nowrap {
    flex-wrap: nowrap !important
}

.flex-wrap-reverse {
    flex-wrap: wrap-reverse !important
}

.flex-fill {
    flex: 1 1 auto !important
}

.flex-grow-0 {
    flex-grow: 0 !important
}

.flex-grow-1 {
    flex-grow: 1 !important
}

.flex-shrink-0 {
    flex-shrink: 0 !important
}

.flex-shrink-1 {
    flex-shrink: 1 !important
}

.justify-content-start {
    justify-content: flex-start !important
}

.justify-content-end {
    justify-content: flex-end !important
}

.justify-content-center {
    justify-content: center !important
}

.justify-content-between {
    justify-content: space-between !important
}

.justify-content-around {
    justify-content: space-around !important
}

.align-items-start {
    align-items: flex-start !important
}

.align-items-end {
    align-items: flex-end !important
}

.align-items-center {
    align-items: center !important
}

.align-items-baseline {
    align-items: baseline !important
}

.align-items-stretch {
    align-items: stretch !important
}

.align-content-start {
    align-content: flex-start !important
}

.align-content-end {
    align-content: flex-end !important
}

.align-content-center {
    align-content: center !important
}

.align-content-between {
    align-content: space-between !important
}

.align-content-around {
    align-content: space-around !important
}

.align-content-stretch {
    align-content: stretch !important
}

.align-self-auto {
    align-self: auto !important
}

.align-self-start {
    align-self: flex-start !important
}

.align-self-end {
    align-self: flex-end !important
}

.align-self-center {
    align-self: center !important
}

.align-self-baseline {
    align-self: baseline !important
}

.align-self-stretch {
    align-self: stretch !important
}

@media (min-width: 576px) {
    .flex-sm-row {
        flex-direction: row !important
    }

    .flex-sm-column {
        flex-direction: column !important
    }

    .flex-sm-row-reverse {
        flex-direction: row-reverse !important
    }

    .flex-sm-column-reverse {
        flex-direction: column-reverse !important
    }

    .flex-sm-wrap {
        flex-wrap: wrap !important
    }

    .flex-sm-nowrap {
        flex-wrap: nowrap !important
    }

    .flex-sm-wrap-reverse {
        flex-wrap: wrap-reverse !important
    }

    .flex-sm-fill {
        flex: 1 1 auto !important
    }

    .flex-sm-grow-0 {
        flex-grow: 0 !important
    }

    .flex-sm-grow-1 {
        flex-grow: 1 !important
    }

    .flex-sm-shrink-0 {
        flex-shrink: 0 !important
    }

    .flex-sm-shrink-1 {
        flex-shrink: 1 !important
    }

    .justify-content-sm-start {
        justify-content: flex-start !important
    }

    .justify-content-sm-end {
        justify-content: flex-end !important
    }

    .justify-content-sm-center {
        justify-content: center !important
    }

    .justify-content-sm-between {
        justify-content: space-between !important
    }

    .justify-content-sm-around {
        justify-content: space-around !important
    }

    .align-items-sm-start {
        align-items: flex-start !important
    }

    .align-items-sm-end {
        align-items: flex-end !important
    }

    .align-items-sm-center {
        align-items: center !important
    }

    .align-items-sm-baseline {
        align-items: baseline !important
    }

    .align-items-sm-stretch {
        align-items: stretch !important
    }

    .align-content-sm-start {
        align-content: flex-start !important
    }

    .align-content-sm-end {
        align-content: flex-end !important
    }

    .align-content-sm-center {
        align-content: center !important
    }

    .align-content-sm-between {
        align-content: space-between !important
    }

    .align-content-sm-around {
        align-content: space-around !important
    }

    .align-content-sm-stretch {
        align-content: stretch !important
    }

    .align-self-sm-auto {
        align-self: auto !important
    }

    .align-self-sm-start {
        align-self: flex-start !important
    }

    .align-self-sm-end {
        align-self: flex-end !important
    }

    .align-self-sm-center {
        align-self: center !important
    }

    .align-self-sm-baseline {
        align-self: baseline !important
    }

    .align-self-sm-stretch {
        align-self: stretch !important
    }
}

@media (min-width: 768px) {
    .flex-md-row {
        flex-direction: row !important
    }

    .flex-md-column {
        flex-direction: column !important
    }

    .flex-md-row-reverse {
        flex-direction: row-reverse !important
    }

    .flex-md-column-reverse {
        flex-direction: column-reverse !important
    }

    .flex-md-wrap {
        flex-wrap: wrap !important
    }

    .flex-md-nowrap {
        flex-wrap: nowrap !important
    }

    .flex-md-wrap-reverse {
        flex-wrap: wrap-reverse !important
    }

    .flex-md-fill {
        flex: 1 1 auto !important
    }

    .flex-md-grow-0 {
        flex-grow: 0 !important
    }

    .flex-md-grow-1 {
        flex-grow: 1 !important
    }

    .flex-md-shrink-0 {
        flex-shrink: 0 !important
    }

    .flex-md-shrink-1 {
        flex-shrink: 1 !important
    }

    .justify-content-md-start {
        justify-content: flex-start !important
    }

    .justify-content-md-end {
        justify-content: flex-end !important
    }

    .justify-content-md-center {
        justify-content: center !important
    }

    .justify-content-md-between {
        justify-content: space-between !important
    }

    .justify-content-md-around {
        justify-content: space-around !important
    }

    .align-items-md-start {
        align-items: flex-start !important
    }

    .align-items-md-end {
        align-items: flex-end !important
    }

    .align-items-md-center {
        align-items: center !important
    }

    .align-items-md-baseline {
        align-items: baseline !important
    }

    .align-items-md-stretch {
        align-items: stretch !important
    }

    .align-content-md-start {
        align-content: flex-start !important
    }

    .align-content-md-end {
        align-content: flex-end !important
    }

    .align-content-md-center {
        align-content: center !important
    }

    .align-content-md-between {
        align-content: space-between !important
    }

    .align-content-md-around {
        align-content: space-around !important
    }

    .align-content-md-stretch {
        align-content: stretch !important
    }

    .align-self-md-auto {
        align-self: auto !important
    }

    .align-self-md-start {
        align-self: flex-start !important
    }

    .align-self-md-end {
        align-self: flex-end !important
    }

    .align-self-md-center {
        align-self: center !important
    }

    .align-self-md-baseline {
        align-self: baseline !important
    }

    .align-self-md-stretch {
        align-self: stretch !important
    }
}

@media (min-width: 992px) {
    .flex-lg-row {
        flex-direction: row !important
    }

    .flex-lg-column {
        flex-direction: column !important
    }

    .flex-lg-row-reverse {
        flex-direction: row-reverse !important
    }

    .flex-lg-column-reverse {
        flex-direction: column-reverse !important
    }

    .flex-lg-wrap {
        flex-wrap: wrap !important
    }

    .flex-lg-nowrap {
        flex-wrap: nowrap !important
    }

    .flex-lg-wrap-reverse {
        flex-wrap: wrap-reverse !important
    }

    .flex-lg-fill {
        flex: 1 1 auto !important
    }

    .flex-lg-grow-0 {
        flex-grow: 0 !important
    }

    .flex-lg-grow-1 {
        flex-grow: 1 !important
    }

    .flex-lg-shrink-0 {
        flex-shrink: 0 !important
    }

    .flex-lg-shrink-1 {
        flex-shrink: 1 !important
    }

    .justify-content-lg-start {
        justify-content: flex-start !important
    }

    .justify-content-lg-end {
        justify-content: flex-end !important
    }

    .justify-content-lg-center {
        justify-content: center !important
    }

    .justify-content-lg-between {
        justify-content: space-between !important
    }

    .justify-content-lg-around {
        justify-content: space-around !important
    }

    .align-items-lg-start {
        align-items: flex-start !important
    }

    .align-items-lg-end {
        align-items: flex-end !important
    }

    .align-items-lg-center {
        align-items: center !important
    }

    .align-items-lg-baseline {
        align-items: baseline !important
    }

    .align-items-lg-stretch {
        align-items: stretch !important
    }

    .align-content-lg-start {
        align-content: flex-start !important
    }

    .align-content-lg-end {
        align-content: flex-end !important
    }

    .align-content-lg-center {
        align-content: center !important
    }

    .align-content-lg-between {
        align-content: space-between !important
    }

    .align-content-lg-around {
        align-content: space-around !important
    }

    .align-content-lg-stretch {
        align-content: stretch !important
    }

    .align-self-lg-auto {
        align-self: auto !important
    }

    .align-self-lg-start {
        align-self: flex-start !important
    }

    .align-self-lg-end {
        align-self: flex-end !important
    }

    .align-self-lg-center {
        align-self: center !important
    }

    .align-self-lg-baseline {
        align-self: baseline !important
    }

    .align-self-lg-stretch {
        align-self: stretch !important
    }
}

@media (min-width: 1200px) {
    .flex-xl-row {
        flex-direction: row !important
    }

    .flex-xl-column {
        flex-direction: column !important
    }

    .flex-xl-row-reverse {
        flex-direction: row-reverse !important
    }

    .flex-xl-column-reverse {
        flex-direction: column-reverse !important
    }

    .flex-xl-wrap {
        flex-wrap: wrap !important
    }

    .flex-xl-nowrap {
        flex-wrap: nowrap !important
    }

    .flex-xl-wrap-reverse {
        flex-wrap: wrap-reverse !important
    }

    .flex-xl-fill {
        flex: 1 1 auto !important
    }

    .flex-xl-grow-0 {
        flex-grow: 0 !important
    }

    .flex-xl-grow-1 {
        flex-grow: 1 !important
    }

    .flex-xl-shrink-0 {
        flex-shrink: 0 !important
    }

    .flex-xl-shrink-1 {
        flex-shrink: 1 !important
    }

    .justify-content-xl-start {
        justify-content: flex-start !important
    }

    .justify-content-xl-end {
        justify-content: flex-end !important
    }

    .justify-content-xl-center {
        justify-content: center !important
    }

    .justify-content-xl-between {
        justify-content: space-between !important
    }

    .justify-content-xl-around {
        justify-content: space-around !important
    }

    .align-items-xl-start {
        align-items: flex-start !important
    }

    .align-items-xl-end {
        align-items: flex-end !important
    }

    .align-items-xl-center {
        align-items: center !important
    }

    .align-items-xl-baseline {
        align-items: baseline !important
    }

    .align-items-xl-stretch {
        align-items: stretch !important
    }

    .align-content-xl-start {
        align-content: flex-start !important
    }

    .align-content-xl-end {
        align-content: flex-end !important
    }

    .align-content-xl-center {
        align-content: center !important
    }

    .align-content-xl-between {
        align-content: space-between !important
    }

    .align-content-xl-around {
        align-content: space-around !important
    }

    .align-content-xl-stretch {
        align-content: stretch !important
    }

    .align-self-xl-auto {
        align-self: auto !important
    }

    .align-self-xl-start {
        align-self: flex-start !important
    }

    .align-self-xl-end {
        align-self: flex-end !important
    }

    .align-self-xl-center {
        align-self: center !important
    }

    .align-self-xl-baseline {
        align-self: baseline !important
    }

    .align-self-xl-stretch {
        align-self: stretch !important
    }
}

.float-left {
    float: left !important
}

.float-right {
    float: right !important
}

.float-none {
    float: none !important
}

@media (min-width: 576px) {
    .float-sm-left {
        float: left !important
    }

    .float-sm-right {
        float: right !important
    }

    .float-sm-none {
        float: none !important
    }
}

@media (min-width: 768px) {
    .float-md-left {
        float: left !important
    }

    .float-md-right {
        float: right !important
    }

    .float-md-none {
        float: none !important
    }
}

@media (min-width: 992px) {
    .float-lg-left {
        float: left !important
    }

    .float-lg-right {
        float: right !important
    }

    .float-lg-none {
        float: none !important
    }
}

@media (min-width: 1400px) {
    .float-xl-left {
        float: left !important
    }

    .float-xl-right {
        float: right !important
    }

    .float-xl-none {
        float: none !important
    }
}

.col, .col-1, .col-10, .col-11, .col-12, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-lg, .col-lg-1, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-md, .col-md-1, .col-md-10, .col-md-11, .col-md-12, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-sm, .col-sm-1, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-xl, .col-xl-1, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9 {
    position: relative;
    width: 100%;
    min-height: 1px;
    padding-right: 9px;
    padding-left: 9px
}

@media (min-width: 576px) {
    .col, .col-1, .col-10, .col-11, .col-12, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-lg, .col-lg-1, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-md, .col-md-1, .col-md-10, .col-md-11, .col-md-12, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-sm, .col-sm-1, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-xl, .col-xl-1, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9 {
        padding-right: 9px;
        padding-left: 9px
    }
}

@media (min-width: 768px) {
    .col, .col-1, .col-10, .col-11, .col-12, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-lg, .col-lg-1, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-md, .col-md-1, .col-md-10, .col-md-11, .col-md-12, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-sm, .col-sm-1, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-xl, .col-xl-1, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9 {
        padding-right: 9px;
        padding-left: 9px
    }
}

@media (min-width: 992px) {
    .col, .col-1, .col-10, .col-11, .col-12, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-lg, .col-lg-1, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-md, .col-md-1, .col-md-10, .col-md-11, .col-md-12, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-sm, .col-sm-1, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-xl, .col-xl-1, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9 {
        padding-right: 9px;
        padding-left: 9px
    }
}

@media (min-width: 1400px) {
    .col, .col-1, .col-10, .col-11, .col-12, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-lg, .col-lg-1, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-md, .col-md-1, .col-md-10, .col-md-11, .col-md-12, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-sm, .col-sm-1, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-xl, .col-xl-1, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9 {
        padding-right: 9px;
        padding-left: 9px
    }
}

.container {
    position: relative;
    margin-left: auto;
    margin-right: auto;
    padding-right: 9px;
    padding-left: 9px
}

@media (min-width: 576px) {
    .container {
        padding-right: 9px;
        padding-left: 9px
    }
}

@media (min-width: 768px) {
    .container {
        padding-right: 9px;
        padding-left: 9px
    }
}

@media (min-width: 992px) {
    .container {
        padding-right: 9px;
        padding-left: 9px
    }
}

@media (min-width: 1400px) {
    .container {
        padding-right: 9px;
        padding-left: 9px
    }
}

@media (min-width: 576px) {
    .container {
        width: 540px;
        max-width: 100%
    }
}

@media (min-width: 768px) {
    .container {
        width: 720px;
        max-width: 100%
    }
}

@media (min-width: 992px) {
    .container {
        width: 960px;
        max-width: 100%
    }
}

@media (min-width: 1400px) {
    .container {
        width: 1380px;
        max-width: 100%
    }
}

.container-fluid {
    position: relative;
    margin-left: auto;
    margin-right: auto;
    padding-right: 9px;
    padding-left: 9px
}

@media (min-width: 576px) {
    .container-fluid {
        padding-right: 9px;
        padding-left: 9px
    }
}

@media (min-width: 768px) {
    .container-fluid {
        padding-right: 9px;
        padding-left: 9px
    }
}

@media (min-width: 992px) {
    .container-fluid {
        padding-right: 9px;
        padding-left: 9px
    }
}

@media (min-width: 1400px) {
    .container-fluid {
        padding-right: 9px;
        padding-left: 9px
    }
}

.img-fluid {
    max-width: 100%;
    height: auto
}

.opacity-1 {
    opacity: 1
}

.opacity-0 {
    opacity: 0
}

.opacity-0-1 {
    opacity: .1
}

.opacity-0-2 {
    opacity: .2
}

.opacity-0-3 {
    opacity: .3
}

.opacity-0-4 {
    opacity: .4
}

.opacity-0-5 {
    opacity: .5
}

.opacity-0-6, .opacity-0-7 {
    opacity: .7
}

.opacity-0-8 {
    opacity: .8
}

.opacity-0-9 {
    opacity: .9
}

.overflow-auto {
    overflow: auto !important
}

.overflow-hidden {
    overflow: hidden !important
}

.position-static {
    position: static !important
}

.position-relative {
    position: relative !important
}

.position-absolute {
    position: absolute !important
}

.position-fixed {
    position: fixed !important
}

.position-sticky {
    position: -webkit-sticky !important;
    position: sticky !important
}

.fixed-bottom, .fixed-top {
    position: fixed;
    right: 0;
    left: 0;
    z-index: 1030
}

.fixed-top {
    top: 0
}

.fixed-bottom {
    bottom: 0
}

@supports ((position:-webkit-sticky) or (position:sticky)) {
    .sticky-top {
        position: -webkit-sticky;
        position: sticky;
        top: 0;
        z-index: 1020
    }
}

.sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border: 0
}

.sr-only-focusable:active, .sr-only-focusable:focus {
    position: static;
    width: auto;
    height: auto;
    overflow: visible;
    clip: auto;
    white-space: normal
}

.shadow-sm {
    box-shadow: 0 .125rem .25rem rgba(0, 0, 0, .075) !important
}

.shadow {
    box-shadow: 0 .5rem 1rem rgba(0, 0, 0, .15)
}

.shadow-lg {
    box-shadow: 0 1rem 3rem rgba(0, 0, 0, .175) !important
}

.shadow-none {
    box-shadow: none !important
}

.w-25 {
    width: 25% !important
}

.w-50 {
    width: 50% !important
}

.w-75 {
    width: 75% !important
}

.w-100 {
    width: 100% !important
}

.w-auto {
    width: auto !important
}

.h-25 {
    height: 25% !important
}

.h-50 {
    height: 50% !important
}

.h-75 {
    height: 75% !important
}

.h-100 {
    height: 100% !important
}

.h-auto {
    height: auto !important
}

.mw-100 {
    max-width: 100% !important
}

.mh-100 {
    max-height: 100% !important
}

.min-vw-100 {
    min-width: 100vw !important
}

.min-vh-100 {
    min-height: 100vh !important
}

.vw-100 {
    width: 100vw !important
}

.vh-100 {
    height: 100vh !important
}

.m-0 {
    margin: 0 !important
}

.mt-0, .my-0 {
    margin-top: 0 !important
}

.mb-0, .my-0 {
    margin-bottom: 0 !important
}

.dir-ltr .mr-0, .dir-ltr .mx-0 {
    margin-right: 0 !important
}

.dir-ltr .ml-0, .dir-ltr .mx-0, .dir-rtl .mr-0, .dir-rtl .mx-0 {
    margin-left: 0 !important
}

.dir-rtl .ml-0, .dir-rtl .mx-0 {
    margin-right: 0 !important
}

.m-1 {
    margin: .25rem !important
}

.mt-1, .my-1 {
    margin-top: .25rem !important
}

.mb-1, .my-1 {
    margin-bottom: .25rem !important
}

.dir-ltr .mr-1, .dir-ltr .mx-1 {
    margin-right: .25rem !important
}

.dir-ltr .ml-1, .dir-ltr .mx-1, .dir-rtl .mr-1, .dir-rtl .mx-1 {
    margin-left: .25rem !important
}

.dir-rtl .ml-1, .dir-rtl .mx-1 {
    margin-right: .25rem !important
}

.m-2 {
    margin: .5rem !important
}

.mt-2, .my-2 {
    margin-top: .5rem !important
}

.mb-2, .my-2 {
    margin-bottom: .5rem !important
}

.dir-ltr .mr-2, .dir-ltr .mx-2 {
    margin-right: .5rem !important
}

.dir-ltr .ml-2, .dir-ltr .mx-2, .dir-rtl .mr-2, .dir-rtl .mx-2 {
    margin-left: .5rem !important
}

.dir-rtl .ml-2, .dir-rtl .mx-2 {
    margin-right: .5rem !important
}

.m-3 {
    margin: 1rem !important
}

.mt-3, .my-3 {
    margin-top: 1rem !important
}

.mb-3, .my-3 {
    margin-bottom: 1rem !important
}

.dir-ltr .mr-3, .dir-ltr .mx-3 {
    margin-right: 1rem !important
}

.dir-ltr .ml-3, .dir-ltr .mx-3, .dir-rtl .mr-3, .dir-rtl .mx-3 {
    margin-left: 1rem !important
}

.dir-rtl .ml-3, .dir-rtl .mx-3 {
    margin-right: 1rem !important
}

.m-4 {
    margin: 1.5rem !important
}

.mt-4, .my-4 {
    margin-top: 1.5rem !important
}

.mb-4, .my-4 {
    margin-bottom: 1.5rem !important
}

.dir-ltr .mr-4, .dir-ltr .mx-4 {
    margin-right: 1.5rem !important
}

.dir-ltr .ml-4, .dir-ltr .mx-4, .dir-rtl .mr-4, .dir-rtl .mx-4 {
    margin-left: 1.5rem !important
}

.dir-rtl .ml-4, .dir-rtl .mx-4 {
    margin-right: 1.5rem !important
}

.m-5 {
    margin: 3rem !important
}

.mt-5, .my-5 {
    margin-top: 3rem !important
}

.mb-5, .my-5 {
    margin-bottom: 3rem !important
}

.dir-ltr .mr-5, .dir-ltr .mx-5 {
    margin-right: 3rem !important
}

.dir-ltr .ml-5, .dir-ltr .mx-5, .dir-rtl .mr-5, .dir-rtl .mx-5 {
    margin-left: 3rem !important
}

.dir-rtl .ml-5, .dir-rtl .mx-5 {
    margin-right: 3rem !important
}

.p-0 {
    padding: 0 !important
}

.pt-0, .py-0 {
    padding-top: 0 !important
}

.pb-0, .py-0 {
    padding-bottom: 0 !important
}

.dir-ltr .pr-0, .dir-ltr .px-0 {
    padding-right: 0 !important
}

.dir-ltr .pl-0, .dir-ltr .px-0, .dir-rtl .pr-0, .dir-rtl .px-0 {
    padding-left: 0 !important
}

.dir-rtl .pl-0, .dir-rtl .px-0 {
    padding-right: 0 !important
}

.p-1 {
    padding: .25rem !important
}

.pt-1, .py-1 {
    padding-top: .25rem !important
}

.pb-1, .py-1 {
    padding-bottom: .25rem !important
}

.dir-ltr .pr-1, .dir-ltr .px-1 {
    padding-right: .25rem !important
}

.dir-ltr .pl-1, .dir-ltr .px-1, .dir-rtl .pr-1, .dir-rtl .px-1 {
    padding-left: .25rem !important
}

.dir-rtl .pl-1, .dir-rtl .px-1 {
    padding-right: .25rem !important
}

.p-2 {
    padding: .5rem !important
}

.pt-2, .py-2 {
    padding-top: .5rem !important
}

.pb-2, .py-2 {
    padding-bottom: .5rem !important
}

.dir-ltr .pr-2, .dir-ltr .px-2 {
    padding-right: .5rem !important
}

.dir-ltr .pl-2, .dir-ltr .px-2, .dir-rtl .pr-2, .dir-rtl .px-2 {
    padding-left: .5rem !important
}

.dir-rtl .pl-2, .dir-rtl .px-2 {
    padding-right: .5rem !important
}

.p-3 {
    padding: 1rem !important
}

.pt-3, .py-3 {
    padding-top: 1rem !important
}

.pb-3, .py-3 {
    padding-bottom: 1rem !important
}

.dir-ltr .pr-3, .dir-ltr .px-3 {
    padding-right: 1rem !important
}

.dir-ltr .pl-3, .dir-ltr .px-3, .dir-rtl .pr-3, .dir-rtl .px-3 {
    padding-left: 1rem !important
}

.dir-rtl .pl-3, .dir-rtl .px-3 {
    padding-right: 1rem !important
}

.p-4 {
    padding: 1.5rem !important
}

.pt-4, .py-4 {
    padding-top: 1.5rem !important
}

.pb-4, .py-4 {
    padding-bottom: 1.5rem !important
}

.dir-ltr .pr-4, .dir-ltr .px-4 {
    padding-right: 1.5rem !important
}

.dir-ltr .pl-4, .dir-ltr .px-4, .dir-rtl .pr-4, .dir-rtl .px-4 {
    padding-left: 1.5rem !important
}

.dir-rtl .pl-4, .dir-rtl .px-4 {
    padding-right: 1.5rem !important
}

.p-5 {
    padding: 3rem !important
}

.pt-5, .py-5 {
    padding-top: 3rem !important
}

.pb-5, .py-5 {
    padding-bottom: 3rem !important
}

.dir-ltr .pr-5, .dir-ltr .px-5 {
    padding-right: 3rem !important
}

.dir-ltr .pl-5, .dir-ltr .px-5, .dir-rtl .pr-5, .dir-rtl .px-5 {
    padding-left: 3rem !important
}

.dir-rtl .pl-5, .dir-rtl .px-5 {
    padding-right: 3rem !important
}

.m-n1 {
    margin: -.25rem !important
}

.mt-n1, .my-n1 {
    margin-top: -.25rem !important
}

.mr-n1, .mx-n1 {
    margin-right: -.25rem !important
}

.mb-n1, .my-n1 {
    margin-bottom: -.25rem !important
}

.ml-n1, .mx-n1 {
    margin-left: -.25rem !important
}

.m-n2 {
    margin: -.5rem !important
}

.mt-n2, .my-n2 {
    margin-top: -.5rem !important
}

.mr-n2, .mx-n2 {
    margin-right: -.5rem !important
}

.mb-n2, .my-n2 {
    margin-bottom: -.5rem !important
}

.ml-n2, .mx-n2 {
    margin-left: -.5rem !important
}

.m-n3 {
    margin: -1rem !important
}

.mt-n3, .my-n3 {
    margin-top: -1rem !important
}

.mr-n3, .mx-n3 {
    margin-right: -1rem !important
}

.mb-n3, .my-n3 {
    margin-bottom: -1rem !important
}

.ml-n3, .mx-n3 {
    margin-left: -1rem !important
}

.m-n4 {
    margin: -1.5rem !important
}

.mt-n4, .my-n4 {
    margin-top: -1.5rem !important
}

.mr-n4, .mx-n4 {
    margin-right: -1.5rem !important
}

.mb-n4, .my-n4 {
    margin-bottom: -1.5rem !important
}

.ml-n4, .mx-n4 {
    margin-left: -1.5rem !important
}

.m-n5 {
    margin: -3rem !important
}

.mt-n5, .my-n5 {
    margin-top: -3rem !important
}

.mr-n5, .mx-n5 {
    margin-right: -3rem !important
}

.mb-n5, .my-n5 {
    margin-bottom: -3rem !important
}

.ml-n5, .mx-n5 {
    margin-left: -3rem !important
}

.m-auto {
    margin: auto !important
}

.mt-auto, .my-auto {
    margin-top: auto !important
}

.mr-auto, .mx-auto {
    margin-right: auto !important
}

.mb-auto, .my-auto {
    margin-bottom: auto !important
}

.ml-auto, .mx-auto {
    margin-left: auto !important
}

@media (min-width: 576px) {
    .m-sm-0 {
        margin: 0 !important
    }

    .mt-sm-0, .my-sm-0 {
        margin-top: 0 !important
    }

    .mb-sm-0, .my-sm-0 {
        margin-bottom: 0 !important
    }

    .dir-ltr .mr-sm-0, .dir-ltr .mx-sm-0 {
        margin-right: 0 !important
    }

    .dir-ltr .ml-sm-0, .dir-ltr .mx-sm-0, .dir-rtl .mr-sm-0, .dir-rtl .mx-sm-0 {
        margin-left: 0 !important
    }

    .dir-rtl .ml-sm-0, .dir-rtl .mx-sm-0 {
        margin-right: 0 !important
    }

    .m-sm-1 {
        margin: .25rem !important
    }

    .mt-sm-1, .my-sm-1 {
        margin-top: .25rem !important
    }

    .mb-sm-1, .my-sm-1 {
        margin-bottom: .25rem !important
    }

    .dir-ltr .mr-sm-1, .dir-ltr .mx-sm-1 {
        margin-right: .25rem !important
    }

    .dir-ltr .ml-sm-1, .dir-ltr .mx-sm-1, .dir-rtl .mr-sm-1, .dir-rtl .mx-sm-1 {
        margin-left: .25rem !important
    }

    .dir-rtl .ml-sm-1, .dir-rtl .mx-sm-1 {
        margin-right: .25rem !important
    }

    .m-sm-2 {
        margin: .5rem !important
    }

    .mt-sm-2, .my-sm-2 {
        margin-top: .5rem !important
    }

    .mb-sm-2, .my-sm-2 {
        margin-bottom: .5rem !important
    }

    .dir-ltr .mr-sm-2, .dir-ltr .mx-sm-2 {
        margin-right: .5rem !important
    }

    .dir-ltr .ml-sm-2, .dir-ltr .mx-sm-2, .dir-rtl .mr-sm-2, .dir-rtl .mx-sm-2 {
        margin-left: .5rem !important
    }

    .dir-rtl .ml-sm-2, .dir-rtl .mx-sm-2 {
        margin-right: .5rem !important
    }

    .m-sm-3 {
        margin: 1rem !important
    }

    .mt-sm-3, .my-sm-3 {
        margin-top: 1rem !important
    }

    .mb-sm-3, .my-sm-3 {
        margin-bottom: 1rem !important
    }

    .dir-ltr .mr-sm-3, .dir-ltr .mx-sm-3 {
        margin-right: 1rem !important
    }

    .dir-ltr .ml-sm-3, .dir-ltr .mx-sm-3, .dir-rtl .mr-sm-3, .dir-rtl .mx-sm-3 {
        margin-left: 1rem !important
    }

    .dir-rtl .ml-sm-3, .dir-rtl .mx-sm-3 {
        margin-right: 1rem !important
    }

    .m-sm-4 {
        margin: 1.5rem !important
    }

    .mt-sm-4, .my-sm-4 {
        margin-top: 1.5rem !important
    }

    .mb-sm-4, .my-sm-4 {
        margin-bottom: 1.5rem !important
    }

    .dir-ltr .mr-sm-4, .dir-ltr .mx-sm-4 {
        margin-right: 1.5rem !important
    }

    .dir-ltr .ml-sm-4, .dir-ltr .mx-sm-4, .dir-rtl .mr-sm-4, .dir-rtl .mx-sm-4 {
        margin-left: 1.5rem !important
    }

    .dir-rtl .ml-sm-4, .dir-rtl .mx-sm-4 {
        margin-right: 1.5rem !important
    }

    .m-sm-5 {
        margin: 3rem !important
    }

    .mt-sm-5, .my-sm-5 {
        margin-top: 3rem !important
    }

    .mb-sm-5, .my-sm-5 {
        margin-bottom: 3rem !important
    }

    .dir-ltr .mr-sm-5, .dir-ltr .mx-sm-5 {
        margin-right: 3rem !important
    }

    .dir-ltr .ml-sm-5, .dir-ltr .mx-sm-5, .dir-rtl .mr-sm-5, .dir-rtl .mx-sm-5 {
        margin-left: 3rem !important
    }

    .dir-rtl .ml-sm-5, .dir-rtl .mx-sm-5 {
        margin-right: 3rem !important
    }

    .p-sm-0 {
        padding: 0 !important
    }

    .pt-sm-0, .py-sm-0 {
        padding-top: 0 !important
    }

    .pb-sm-0, .py-sm-0 {
        padding-bottom: 0 !important
    }

    .dir-ltr .pr-sm-0, .dir-ltr .px-sm-0 {
        padding-right: 0 !important
    }

    .dir-ltr .pl-sm-0, .dir-ltr .px-sm-0, .dir-rtl .pr-sm-0, .dir-rtl .px-sm-0 {
        padding-left: 0 !important
    }

    .dir-rtl .pl-sm-0, .dir-rtl .px-sm-0 {
        padding-right: 0 !important
    }

    .p-sm-1 {
        padding: .25rem !important
    }

    .pt-sm-1, .py-sm-1 {
        padding-top: .25rem !important
    }

    .pb-sm-1, .py-sm-1 {
        padding-bottom: .25rem !important
    }

    .dir-ltr .pr-sm-1, .dir-ltr .px-sm-1 {
        padding-right: .25rem !important
    }

    .dir-ltr .pl-sm-1, .dir-ltr .px-sm-1, .dir-rtl .pr-sm-1, .dir-rtl .px-sm-1 {
        padding-left: .25rem !important
    }

    .dir-rtl .pl-sm-1, .dir-rtl .px-sm-1 {
        padding-right: .25rem !important
    }

    .p-sm-2 {
        padding: .5rem !important
    }

    .pt-sm-2, .py-sm-2 {
        padding-top: .5rem !important
    }

    .pb-sm-2, .py-sm-2 {
        padding-bottom: .5rem !important
    }

    .dir-ltr .pr-sm-2, .dir-ltr .px-sm-2 {
        padding-right: .5rem !important
    }

    .dir-ltr .pl-sm-2, .dir-ltr .px-sm-2, .dir-rtl .pr-sm-2, .dir-rtl .px-sm-2 {
        padding-left: .5rem !important
    }

    .dir-rtl .pl-sm-2, .dir-rtl .px-sm-2 {
        padding-right: .5rem !important
    }

    .p-sm-3 {
        padding: 1rem !important
    }

    .pt-sm-3, .py-sm-3 {
        padding-top: 1rem !important
    }

    .pb-sm-3, .py-sm-3 {
        padding-bottom: 1rem !important
    }

    .dir-ltr .pr-sm-3, .dir-ltr .px-sm-3 {
        padding-right: 1rem !important
    }

    .dir-ltr .pl-sm-3, .dir-ltr .px-sm-3, .dir-rtl .pr-sm-3, .dir-rtl .px-sm-3 {
        padding-left: 1rem !important
    }

    .dir-rtl .pl-sm-3, .dir-rtl .px-sm-3 {
        padding-right: 1rem !important
    }

    .p-sm-4 {
        padding: 1.5rem !important
    }

    .pt-sm-4, .py-sm-4 {
        padding-top: 1.5rem !important
    }

    .pb-sm-4, .py-sm-4 {
        padding-bottom: 1.5rem !important
    }

    .dir-ltr .pr-sm-4, .dir-ltr .px-sm-4 {
        padding-right: 1.5rem !important
    }

    .dir-ltr .pl-sm-4, .dir-ltr .px-sm-4, .dir-rtl .pr-sm-4, .dir-rtl .px-sm-4 {
        padding-left: 1.5rem !important
    }

    .dir-rtl .pl-sm-4, .dir-rtl .px-sm-4 {
        padding-right: 1.5rem !important
    }

    .p-sm-5 {
        padding: 3rem !important
    }

    .pt-sm-5, .py-sm-5 {
        padding-top: 3rem !important
    }

    .pb-sm-5, .py-sm-5 {
        padding-bottom: 3rem !important
    }

    .dir-ltr .pr-sm-5, .dir-ltr .px-sm-5 {
        padding-right: 3rem !important
    }

    .dir-ltr .pl-sm-5, .dir-ltr .px-sm-5, .dir-rtl .pr-sm-5, .dir-rtl .px-sm-5 {
        padding-left: 3rem !important
    }

    .dir-rtl .pl-sm-5, .dir-rtl .px-sm-5 {
        padding-right: 3rem !important
    }

    .m-sm-n1 {
        margin: -.25rem !important
    }

    .mt-sm-n1, .my-sm-n1 {
        margin-top: -.25rem !important
    }

    .mr-sm-n1, .mx-sm-n1 {
        margin-right: -.25rem !important
    }

    .mb-sm-n1, .my-sm-n1 {
        margin-bottom: -.25rem !important
    }

    .ml-sm-n1, .mx-sm-n1 {
        margin-left: -.25rem !important
    }

    .m-sm-n2 {
        margin: -.5rem !important
    }

    .mt-sm-n2, .my-sm-n2 {
        margin-top: -.5rem !important
    }

    .mr-sm-n2, .mx-sm-n2 {
        margin-right: -.5rem !important
    }

    .mb-sm-n2, .my-sm-n2 {
        margin-bottom: -.5rem !important
    }

    .ml-sm-n2, .mx-sm-n2 {
        margin-left: -.5rem !important
    }

    .m-sm-n3 {
        margin: -1rem !important
    }

    .mt-sm-n3, .my-sm-n3 {
        margin-top: -1rem !important
    }

    .mr-sm-n3, .mx-sm-n3 {
        margin-right: -1rem !important
    }

    .mb-sm-n3, .my-sm-n3 {
        margin-bottom: -1rem !important
    }

    .ml-sm-n3, .mx-sm-n3 {
        margin-left: -1rem !important
    }

    .m-sm-n4 {
        margin: -1.5rem !important
    }

    .mt-sm-n4, .my-sm-n4 {
        margin-top: -1.5rem !important
    }

    .mr-sm-n4, .mx-sm-n4 {
        margin-right: -1.5rem !important
    }

    .mb-sm-n4, .my-sm-n4 {
        margin-bottom: -1.5rem !important
    }

    .ml-sm-n4, .mx-sm-n4 {
        margin-left: -1.5rem !important
    }

    .m-sm-n5 {
        margin: -3rem !important
    }

    .mt-sm-n5, .my-sm-n5 {
        margin-top: -3rem !important
    }

    .mr-sm-n5, .mx-sm-n5 {
        margin-right: -3rem !important
    }

    .mb-sm-n5, .my-sm-n5 {
        margin-bottom: -3rem !important
    }

    .ml-sm-n5, .mx-sm-n5 {
        margin-left: -3rem !important
    }

    .m-sm-auto {
        margin: auto !important
    }

    .mt-sm-auto, .my-sm-auto {
        margin-top: auto !important
    }

    .mr-sm-auto, .mx-sm-auto {
        margin-right: auto !important
    }

    .mb-sm-auto, .my-sm-auto {
        margin-bottom: auto !important
    }

    .ml-sm-auto, .mx-sm-auto {
        margin-left: auto !important
    }
}

@media (min-width: 768px) {
    .m-md-0 {
        margin: 0 !important
    }

    .mt-md-0, .my-md-0 {
        margin-top: 0 !important
    }

    .mb-md-0, .my-md-0 {
        margin-bottom: 0 !important
    }

    .dir-ltr .mr-md-0, .dir-ltr .mx-md-0 {
        margin-right: 0 !important
    }

    .dir-ltr .ml-md-0, .dir-ltr .mx-md-0, .dir-rtl .mr-md-0, .dir-rtl .mx-md-0 {
        margin-left: 0 !important
    }

    .dir-rtl .ml-md-0, .dir-rtl .mx-md-0 {
        margin-right: 0 !important
    }

    .m-md-1 {
        margin: .25rem !important
    }

    .mt-md-1, .my-md-1 {
        margin-top: .25rem !important
    }

    .mb-md-1, .my-md-1 {
        margin-bottom: .25rem !important
    }

    .dir-ltr .mr-md-1, .dir-ltr .mx-md-1 {
        margin-right: .25rem !important
    }

    .dir-ltr .ml-md-1, .dir-ltr .mx-md-1, .dir-rtl .mr-md-1, .dir-rtl .mx-md-1 {
        margin-left: .25rem !important
    }

    .dir-rtl .ml-md-1, .dir-rtl .mx-md-1 {
        margin-right: .25rem !important
    }

    .m-md-2 {
        margin: .5rem !important
    }

    .mt-md-2, .my-md-2 {
        margin-top: .5rem !important
    }

    .mb-md-2, .my-md-2 {
        margin-bottom: .5rem !important
    }

    .dir-ltr .mr-md-2, .dir-ltr .mx-md-2 {
        margin-right: .5rem !important
    }

    .dir-ltr .ml-md-2, .dir-ltr .mx-md-2, .dir-rtl .mr-md-2, .dir-rtl .mx-md-2 {
        margin-left: .5rem !important
    }

    .dir-rtl .ml-md-2, .dir-rtl .mx-md-2 {
        margin-right: .5rem !important
    }

    .m-md-3 {
        margin: 1rem !important
    }

    .mt-md-3, .my-md-3 {
        margin-top: 1rem !important
    }

    .mb-md-3, .my-md-3 {
        margin-bottom: 1rem !important
    }

    .dir-ltr .mr-md-3, .dir-ltr .mx-md-3 {
        margin-right: 1rem !important
    }

    .dir-ltr .ml-md-3, .dir-ltr .mx-md-3, .dir-rtl .mr-md-3, .dir-rtl .mx-md-3 {
        margin-left: 1rem !important
    }

    .dir-rtl .ml-md-3, .dir-rtl .mx-md-3 {
        margin-right: 1rem !important
    }

    .m-md-4 {
        margin: 1.5rem !important
    }

    .mt-md-4, .my-md-4 {
        margin-top: 1.5rem !important
    }

    .mb-md-4, .my-md-4 {
        margin-bottom: 1.5rem !important
    }

    .dir-ltr .mr-md-4, .dir-ltr .mx-md-4 {
        margin-right: 1.5rem !important
    }

    .dir-ltr .ml-md-4, .dir-ltr .mx-md-4, .dir-rtl .mr-md-4, .dir-rtl .mx-md-4 {
        margin-left: 1.5rem !important
    }

    .dir-rtl .ml-md-4, .dir-rtl .mx-md-4 {
        margin-right: 1.5rem !important
    }

    .m-md-5 {
        margin: 3rem !important
    }

    .mt-md-5, .my-md-5 {
        margin-top: 3rem !important
    }

    .mb-md-5, .my-md-5 {
        margin-bottom: 3rem !important
    }

    .dir-ltr .mr-md-5, .dir-ltr .mx-md-5 {
        margin-right: 3rem !important
    }

    .dir-ltr .ml-md-5, .dir-ltr .mx-md-5, .dir-rtl .mr-md-5, .dir-rtl .mx-md-5 {
        margin-left: 3rem !important
    }

    .dir-rtl .ml-md-5, .dir-rtl .mx-md-5 {
        margin-right: 3rem !important
    }

    .p-md-0 {
        padding: 0 !important
    }

    .pt-md-0, .py-md-0 {
        padding-top: 0 !important
    }

    .pb-md-0, .py-md-0 {
        padding-bottom: 0 !important
    }

    .dir-ltr .pr-md-0, .dir-ltr .px-md-0 {
        padding-right: 0 !important
    }

    .dir-ltr .pl-md-0, .dir-ltr .px-md-0, .dir-rtl .pr-md-0, .dir-rtl .px-md-0 {
        padding-left: 0 !important
    }

    .dir-rtl .pl-md-0, .dir-rtl .px-md-0 {
        padding-right: 0 !important
    }

    .p-md-1 {
        padding: .25rem !important
    }

    .pt-md-1, .py-md-1 {
        padding-top: .25rem !important
    }

    .pb-md-1, .py-md-1 {
        padding-bottom: .25rem !important
    }

    .dir-ltr .pr-md-1, .dir-ltr .px-md-1 {
        padding-right: .25rem !important
    }

    .dir-ltr .pl-md-1, .dir-ltr .px-md-1, .dir-rtl .pr-md-1, .dir-rtl .px-md-1 {
        padding-left: .25rem !important
    }

    .dir-rtl .pl-md-1, .dir-rtl .px-md-1 {
        padding-right: .25rem !important
    }

    .p-md-2 {
        padding: .5rem !important
    }

    .pt-md-2, .py-md-2 {
        padding-top: .5rem !important
    }

    .pb-md-2, .py-md-2 {
        padding-bottom: .5rem !important
    }

    .dir-ltr .pr-md-2, .dir-ltr .px-md-2 {
        padding-right: .5rem !important
    }

    .dir-ltr .pl-md-2, .dir-ltr .px-md-2, .dir-rtl .pr-md-2, .dir-rtl .px-md-2 {
        padding-left: .5rem !important
    }

    .dir-rtl .pl-md-2, .dir-rtl .px-md-2 {
        padding-right: .5rem !important
    }

    .p-md-3 {
        padding: 1rem !important
    }

    .pt-md-3, .py-md-3 {
        padding-top: 1rem !important
    }

    .pb-md-3, .py-md-3 {
        padding-bottom: 1rem !important
    }

    .dir-ltr .pr-md-3, .dir-ltr .px-md-3 {
        padding-right: 1rem !important
    }

    .dir-ltr .pl-md-3, .dir-ltr .px-md-3, .dir-rtl .pr-md-3, .dir-rtl .px-md-3 {
        padding-left: 1rem !important
    }

    .dir-rtl .pl-md-3, .dir-rtl .px-md-3 {
        padding-right: 1rem !important
    }

    .p-md-4 {
        padding: 1.5rem !important
    }

    .pt-md-4, .py-md-4 {
        padding-top: 1.5rem !important
    }

    .pb-md-4, .py-md-4 {
        padding-bottom: 1.5rem !important
    }

    .dir-ltr .pr-md-4, .dir-ltr .px-md-4 {
        padding-right: 1.5rem !important
    }

    .dir-ltr .pl-md-4, .dir-ltr .px-md-4, .dir-rtl .pr-md-4, .dir-rtl .px-md-4 {
        padding-left: 1.5rem !important
    }

    .dir-rtl .pl-md-4, .dir-rtl .px-md-4 {
        padding-right: 1.5rem !important
    }

    .p-md-5 {
        padding: 3rem !important
    }

    .pt-md-5, .py-md-5 {
        padding-top: 3rem !important
    }

    .pb-md-5, .py-md-5 {
        padding-bottom: 3rem !important
    }

    .dir-ltr .pr-md-5, .dir-ltr .px-md-5 {
        padding-right: 3rem !important
    }

    .dir-ltr .pl-md-5, .dir-ltr .px-md-5, .dir-rtl .pr-md-5, .dir-rtl .px-md-5 {
        padding-left: 3rem !important
    }

    .dir-rtl .pl-md-5, .dir-rtl .px-md-5 {
        padding-right: 3rem !important
    }

    .m-md-n1 {
        margin: -.25rem !important
    }

    .mt-md-n1, .my-md-n1 {
        margin-top: -.25rem !important
    }

    .mr-md-n1, .mx-md-n1 {
        margin-right: -.25rem !important
    }

    .mb-md-n1, .my-md-n1 {
        margin-bottom: -.25rem !important
    }

    .ml-md-n1, .mx-md-n1 {
        margin-left: -.25rem !important
    }

    .m-md-n2 {
        margin: -.5rem !important
    }

    .mt-md-n2, .my-md-n2 {
        margin-top: -.5rem !important
    }

    .mr-md-n2, .mx-md-n2 {
        margin-right: -.5rem !important
    }

    .mb-md-n2, .my-md-n2 {
        margin-bottom: -.5rem !important
    }

    .ml-md-n2, .mx-md-n2 {
        margin-left: -.5rem !important
    }

    .m-md-n3 {
        margin: -1rem !important
    }

    .mt-md-n3, .my-md-n3 {
        margin-top: -1rem !important
    }

    .mr-md-n3, .mx-md-n3 {
        margin-right: -1rem !important
    }

    .mb-md-n3, .my-md-n3 {
        margin-bottom: -1rem !important
    }

    .ml-md-n3, .mx-md-n3 {
        margin-left: -1rem !important
    }

    .m-md-n4 {
        margin: -1.5rem !important
    }

    .mt-md-n4, .my-md-n4 {
        margin-top: -1.5rem !important
    }

    .mr-md-n4, .mx-md-n4 {
        margin-right: -1.5rem !important
    }

    .mb-md-n4, .my-md-n4 {
        margin-bottom: -1.5rem !important
    }

    .ml-md-n4, .mx-md-n4 {
        margin-left: -1.5rem !important
    }

    .m-md-n5 {
        margin: -3rem !important
    }

    .mt-md-n5, .my-md-n5 {
        margin-top: -3rem !important
    }

    .mr-md-n5, .mx-md-n5 {
        margin-right: -3rem !important
    }

    .mb-md-n5, .my-md-n5 {
        margin-bottom: -3rem !important
    }

    .ml-md-n5, .mx-md-n5 {
        margin-left: -3rem !important
    }

    .m-md-auto {
        margin: auto !important
    }

    .mt-md-auto, .my-md-auto {
        margin-top: auto !important
    }

    .mr-md-auto, .mx-md-auto {
        margin-right: auto !important
    }

    .mb-md-auto, .my-md-auto {
        margin-bottom: auto !important
    }

    .ml-md-auto, .mx-md-auto {
        margin-left: auto !important
    }
}

@media (min-width: 992px) {
    .m-lg-0 {
        margin: 0 !important
    }

    .mt-lg-0, .my-lg-0 {
        margin-top: 0 !important
    }

    .mb-lg-0, .my-lg-0 {
        margin-bottom: 0 !important
    }

    .dir-ltr .mr-lg-0, .dir-ltr .mx-lg-0 {
        margin-right: 0 !important
    }

    .dir-ltr .ml-lg-0, .dir-ltr .mx-lg-0, .dir-rtl .mr-lg-0, .dir-rtl .mx-lg-0 {
        margin-left: 0 !important
    }

    .dir-rtl .ml-lg-0, .dir-rtl .mx-lg-0 {
        margin-right: 0 !important
    }

    .m-lg-1 {
        margin: .25rem !important
    }

    .mt-lg-1, .my-lg-1 {
        margin-top: .25rem !important
    }

    .mb-lg-1, .my-lg-1 {
        margin-bottom: .25rem !important
    }

    .dir-ltr .mr-lg-1, .dir-ltr .mx-lg-1 {
        margin-right: .25rem !important
    }

    .dir-ltr .ml-lg-1, .dir-ltr .mx-lg-1, .dir-rtl .mr-lg-1, .dir-rtl .mx-lg-1 {
        margin-left: .25rem !important
    }

    .dir-rtl .ml-lg-1, .dir-rtl .mx-lg-1 {
        margin-right: .25rem !important
    }

    .m-lg-2 {
        margin: .5rem !important
    }

    .mt-lg-2, .my-lg-2 {
        margin-top: .5rem !important
    }

    .mb-lg-2, .my-lg-2 {
        margin-bottom: .5rem !important
    }

    .dir-ltr .mr-lg-2, .dir-ltr .mx-lg-2 {
        margin-right: .5rem !important
    }

    .dir-ltr .ml-lg-2, .dir-ltr .mx-lg-2, .dir-rtl .mr-lg-2, .dir-rtl .mx-lg-2 {
        margin-left: .5rem !important
    }

    .dir-rtl .ml-lg-2, .dir-rtl .mx-lg-2 {
        margin-right: .5rem !important
    }

    .m-lg-3 {
        margin: 1rem !important
    }

    .mt-lg-3, .my-lg-3 {
        margin-top: 1rem !important
    }

    .mb-lg-3, .my-lg-3 {
        margin-bottom: 1rem !important
    }

    .dir-ltr .mr-lg-3, .dir-ltr .mx-lg-3 {
        margin-right: 1rem !important
    }

    .dir-ltr .ml-lg-3, .dir-ltr .mx-lg-3, .dir-rtl .mr-lg-3, .dir-rtl .mx-lg-3 {
        margin-left: 1rem !important
    }

    .dir-rtl .ml-lg-3, .dir-rtl .mx-lg-3 {
        margin-right: 1rem !important
    }

    .m-lg-4 {
        margin: 1.5rem !important
    }

    .mt-lg-4, .my-lg-4 {
        margin-top: 1.5rem !important
    }

    .mb-lg-4, .my-lg-4 {
        margin-bottom: 1.5rem !important
    }

    .dir-ltr .mr-lg-4, .dir-ltr .mx-lg-4 {
        margin-right: 1.5rem !important
    }

    .dir-ltr .ml-lg-4, .dir-ltr .mx-lg-4, .dir-rtl .mr-lg-4, .dir-rtl .mx-lg-4 {
        margin-left: 1.5rem !important
    }

    .dir-rtl .ml-lg-4, .dir-rtl .mx-lg-4 {
        margin-right: 1.5rem !important
    }

    .m-lg-5 {
        margin: 3rem !important
    }

    .mt-lg-5, .my-lg-5 {
        margin-top: 3rem !important
    }

    .mb-lg-5, .my-lg-5 {
        margin-bottom: 3rem !important
    }

    .dir-ltr .mr-lg-5, .dir-ltr .mx-lg-5 {
        margin-right: 3rem !important
    }

    .dir-ltr .ml-lg-5, .dir-ltr .mx-lg-5, .dir-rtl .mr-lg-5, .dir-rtl .mx-lg-5 {
        margin-left: 3rem !important
    }

    .dir-rtl .ml-lg-5, .dir-rtl .mx-lg-5 {
        margin-right: 3rem !important
    }

    .p-lg-0 {
        padding: 0 !important
    }

    .pt-lg-0, .py-lg-0 {
        padding-top: 0 !important
    }

    .pb-lg-0, .py-lg-0 {
        padding-bottom: 0 !important
    }

    .dir-ltr .pr-lg-0, .dir-ltr .px-lg-0 {
        padding-right: 0 !important
    }

    .dir-ltr .pl-lg-0, .dir-ltr .px-lg-0, .dir-rtl .pr-lg-0, .dir-rtl .px-lg-0 {
        padding-left: 0 !important
    }

    .dir-rtl .pl-lg-0, .dir-rtl .px-lg-0 {
        padding-right: 0 !important
    }

    .p-lg-1 {
        padding: .25rem !important
    }

    .pt-lg-1, .py-lg-1 {
        padding-top: .25rem !important
    }

    .pb-lg-1, .py-lg-1 {
        padding-bottom: .25rem !important
    }

    .dir-ltr .pr-lg-1, .dir-ltr .px-lg-1 {
        padding-right: .25rem !important
    }

    .dir-ltr .pl-lg-1, .dir-ltr .px-lg-1, .dir-rtl .pr-lg-1, .dir-rtl .px-lg-1 {
        padding-left: .25rem !important
    }

    .dir-rtl .pl-lg-1, .dir-rtl .px-lg-1 {
        padding-right: .25rem !important
    }

    .p-lg-2 {
        padding: .5rem !important
    }

    .pt-lg-2, .py-lg-2 {
        padding-top: .5rem !important
    }

    .pb-lg-2, .py-lg-2 {
        padding-bottom: .5rem !important
    }

    .dir-ltr .pr-lg-2, .dir-ltr .px-lg-2 {
        padding-right: .5rem !important
    }

    .dir-ltr .pl-lg-2, .dir-ltr .px-lg-2, .dir-rtl .pr-lg-2, .dir-rtl .px-lg-2 {
        padding-left: .5rem !important
    }

    .dir-rtl .pl-lg-2, .dir-rtl .px-lg-2 {
        padding-right: .5rem !important
    }

    .p-lg-3 {
        padding: 1rem !important
    }

    .pt-lg-3, .py-lg-3 {
        padding-top: 1rem !important
    }

    .pb-lg-3, .py-lg-3 {
        padding-bottom: 1rem !important
    }

    .dir-ltr .pr-lg-3, .dir-ltr .px-lg-3 {
        padding-right: 1rem !important
    }

    .dir-ltr .pl-lg-3, .dir-ltr .px-lg-3, .dir-rtl .pr-lg-3, .dir-rtl .px-lg-3 {
        padding-left: 1rem !important
    }

    .dir-rtl .pl-lg-3, .dir-rtl .px-lg-3 {
        padding-right: 1rem !important
    }

    .p-lg-4 {
        padding: 1.5rem !important
    }

    .pt-lg-4, .py-lg-4 {
        padding-top: 1.5rem !important
    }

    .pb-lg-4, .py-lg-4 {
        padding-bottom: 1.5rem !important
    }

    .dir-ltr .pr-lg-4, .dir-ltr .px-lg-4 {
        padding-right: 1.5rem !important
    }

    .dir-ltr .pl-lg-4, .dir-ltr .px-lg-4, .dir-rtl .pr-lg-4, .dir-rtl .px-lg-4 {
        padding-left: 1.5rem !important
    }

    .dir-rtl .pl-lg-4, .dir-rtl .px-lg-4 {
        padding-right: 1.5rem !important
    }

    .p-lg-5 {
        padding: 3rem !important
    }

    .pt-lg-5, .py-lg-5 {
        padding-top: 3rem !important
    }

    .pb-lg-5, .py-lg-5 {
        padding-bottom: 3rem !important
    }

    .dir-ltr .pr-lg-5, .dir-ltr .px-lg-5 {
        padding-right: 3rem !important
    }

    .dir-ltr .pl-lg-5, .dir-ltr .px-lg-5, .dir-rtl .pr-lg-5, .dir-rtl .px-lg-5 {
        padding-left: 3rem !important
    }

    .dir-rtl .pl-lg-5, .dir-rtl .px-lg-5 {
        padding-right: 3rem !important
    }

    .m-lg-n1 {
        margin: -.25rem !important
    }

    .mt-lg-n1, .my-lg-n1 {
        margin-top: -.25rem !important
    }

    .mr-lg-n1, .mx-lg-n1 {
        margin-right: -.25rem !important
    }

    .mb-lg-n1, .my-lg-n1 {
        margin-bottom: -.25rem !important
    }

    .ml-lg-n1, .mx-lg-n1 {
        margin-left: -.25rem !important
    }

    .m-lg-n2 {
        margin: -.5rem !important
    }

    .mt-lg-n2, .my-lg-n2 {
        margin-top: -.5rem !important
    }

    .mr-lg-n2, .mx-lg-n2 {
        margin-right: -.5rem !important
    }

    .mb-lg-n2, .my-lg-n2 {
        margin-bottom: -.5rem !important
    }

    .ml-lg-n2, .mx-lg-n2 {
        margin-left: -.5rem !important
    }

    .m-lg-n3 {
        margin: -1rem !important
    }

    .mt-lg-n3, .my-lg-n3 {
        margin-top: -1rem !important
    }

    .mr-lg-n3, .mx-lg-n3 {
        margin-right: -1rem !important
    }

    .mb-lg-n3, .my-lg-n3 {
        margin-bottom: -1rem !important
    }

    .ml-lg-n3, .mx-lg-n3 {
        margin-left: -1rem !important
    }

    .m-lg-n4 {
        margin: -1.5rem !important
    }

    .mt-lg-n4, .my-lg-n4 {
        margin-top: -1.5rem !important
    }

    .mr-lg-n4, .mx-lg-n4 {
        margin-right: -1.5rem !important
    }

    .mb-lg-n4, .my-lg-n4 {
        margin-bottom: -1.5rem !important
    }

    .ml-lg-n4, .mx-lg-n4 {
        margin-left: -1.5rem !important
    }

    .m-lg-n5 {
        margin: -3rem !important
    }

    .mt-lg-n5, .my-lg-n5 {
        margin-top: -3rem !important
    }

    .mr-lg-n5, .mx-lg-n5 {
        margin-right: -3rem !important
    }

    .mb-lg-n5, .my-lg-n5 {
        margin-bottom: -3rem !important
    }

    .ml-lg-n5, .mx-lg-n5 {
        margin-left: -3rem !important
    }

    .m-lg-auto {
        margin: auto !important
    }

    .mt-lg-auto, .my-lg-auto {
        margin-top: auto !important
    }

    .mr-lg-auto, .mx-lg-auto {
        margin-right: auto !important
    }

    .mb-lg-auto, .my-lg-auto {
        margin-bottom: auto !important
    }

    .ml-lg-auto, .mx-lg-auto {
        margin-left: auto !important
    }
}

@media (min-width: 1400px) {
    .m-xl-0 {
        margin: 0 !important
    }

    .mt-xl-0, .my-xl-0 {
        margin-top: 0 !important
    }

    .mb-xl-0, .my-xl-0 {
        margin-bottom: 0 !important
    }

    .dir-ltr .mr-xl-0, .dir-ltr .mx-xl-0 {
        margin-right: 0 !important
    }

    .dir-ltr .ml-xl-0, .dir-ltr .mx-xl-0, .dir-rtl .mr-xl-0, .dir-rtl .mx-xl-0 {
        margin-left: 0 !important
    }

    .dir-rtl .ml-xl-0, .dir-rtl .mx-xl-0 {
        margin-right: 0 !important
    }

    .m-xl-1 {
        margin: .25rem !important
    }

    .mt-xl-1, .my-xl-1 {
        margin-top: .25rem !important
    }

    .mb-xl-1, .my-xl-1 {
        margin-bottom: .25rem !important
    }

    .dir-ltr .mr-xl-1, .dir-ltr .mx-xl-1 {
        margin-right: .25rem !important
    }

    .dir-ltr .ml-xl-1, .dir-ltr .mx-xl-1, .dir-rtl .mr-xl-1, .dir-rtl .mx-xl-1 {
        margin-left: .25rem !important
    }

    .dir-rtl .ml-xl-1, .dir-rtl .mx-xl-1 {
        margin-right: .25rem !important
    }

    .m-xl-2 {
        margin: .5rem !important
    }

    .mt-xl-2, .my-xl-2 {
        margin-top: .5rem !important
    }

    .mb-xl-2, .my-xl-2 {
        margin-bottom: .5rem !important
    }

    .dir-ltr .mr-xl-2, .dir-ltr .mx-xl-2 {
        margin-right: .5rem !important
    }

    .dir-ltr .ml-xl-2, .dir-ltr .mx-xl-2, .dir-rtl .mr-xl-2, .dir-rtl .mx-xl-2 {
        margin-left: .5rem !important
    }

    .dir-rtl .ml-xl-2, .dir-rtl .mx-xl-2 {
        margin-right: .5rem !important
    }

    .m-xl-3 {
        margin: 1rem !important
    }

    .mt-xl-3, .my-xl-3 {
        margin-top: 1rem !important
    }

    .mb-xl-3, .my-xl-3 {
        margin-bottom: 1rem !important
    }

    .dir-ltr .mr-xl-3, .dir-ltr .mx-xl-3 {
        margin-right: 1rem !important
    }

    .dir-ltr .ml-xl-3, .dir-ltr .mx-xl-3, .dir-rtl .mr-xl-3, .dir-rtl .mx-xl-3 {
        margin-left: 1rem !important
    }

    .dir-rtl .ml-xl-3, .dir-rtl .mx-xl-3 {
        margin-right: 1rem !important
    }

    .m-xl-4 {
        margin: 1.5rem !important
    }

    .mt-xl-4, .my-xl-4 {
        margin-top: 1.5rem !important
    }

    .mb-xl-4, .my-xl-4 {
        margin-bottom: 1.5rem !important
    }

    .dir-ltr .mr-xl-4, .dir-ltr .mx-xl-4 {
        margin-right: 1.5rem !important
    }

    .dir-ltr .ml-xl-4, .dir-ltr .mx-xl-4, .dir-rtl .mr-xl-4, .dir-rtl .mx-xl-4 {
        margin-left: 1.5rem !important
    }

    .dir-rtl .ml-xl-4, .dir-rtl .mx-xl-4 {
        margin-right: 1.5rem !important
    }

    .m-xl-5 {
        margin: 3rem !important
    }

    .mt-xl-5, .my-xl-5 {
        margin-top: 3rem !important
    }

    .mb-xl-5, .my-xl-5 {
        margin-bottom: 3rem !important
    }

    .dir-ltr .mr-xl-5, .dir-ltr .mx-xl-5 {
        margin-right: 3rem !important
    }

    .dir-ltr .ml-xl-5, .dir-ltr .mx-xl-5, .dir-rtl .mr-xl-5, .dir-rtl .mx-xl-5 {
        margin-left: 3rem !important
    }

    .dir-rtl .ml-xl-5, .dir-rtl .mx-xl-5 {
        margin-right: 3rem !important
    }

    .p-xl-0 {
        padding: 0 !important
    }

    .pt-xl-0, .py-xl-0 {
        padding-top: 0 !important
    }

    .pb-xl-0, .py-xl-0 {
        padding-bottom: 0 !important
    }

    .dir-ltr .pr-xl-0, .dir-ltr .px-xl-0 {
        padding-right: 0 !important
    }

    .dir-ltr .pl-xl-0, .dir-ltr .px-xl-0, .dir-rtl .pr-xl-0, .dir-rtl .px-xl-0 {
        padding-left: 0 !important
    }

    .dir-rtl .pl-xl-0, .dir-rtl .px-xl-0 {
        padding-right: 0 !important
    }

    .p-xl-1 {
        padding: .25rem !important
    }

    .pt-xl-1, .py-xl-1 {
        padding-top: .25rem !important
    }

    .pb-xl-1, .py-xl-1 {
        padding-bottom: .25rem !important
    }

    .dir-ltr .pr-xl-1, .dir-ltr .px-xl-1 {
        padding-right: .25rem !important
    }

    .dir-ltr .pl-xl-1, .dir-ltr .px-xl-1, .dir-rtl .pr-xl-1, .dir-rtl .px-xl-1 {
        padding-left: .25rem !important
    }

    .dir-rtl .pl-xl-1, .dir-rtl .px-xl-1 {
        padding-right: .25rem !important
    }

    .p-xl-2 {
        padding: .5rem !important
    }

    .pt-xl-2, .py-xl-2 {
        padding-top: .5rem !important
    }

    .pb-xl-2, .py-xl-2 {
        padding-bottom: .5rem !important
    }

    .dir-ltr .pr-xl-2, .dir-ltr .px-xl-2 {
        padding-right: .5rem !important
    }

    .dir-ltr .pl-xl-2, .dir-ltr .px-xl-2, .dir-rtl .pr-xl-2, .dir-rtl .px-xl-2 {
        padding-left: .5rem !important
    }

    .dir-rtl .pl-xl-2, .dir-rtl .px-xl-2 {
        padding-right: .5rem !important
    }

    .p-xl-3 {
        padding: 1rem !important
    }

    .pt-xl-3, .py-xl-3 {
        padding-top: 1rem !important
    }

    .pb-xl-3, .py-xl-3 {
        padding-bottom: 1rem !important
    }

    .dir-ltr .pr-xl-3, .dir-ltr .px-xl-3 {
        padding-right: 1rem !important
    }

    .dir-ltr .pl-xl-3, .dir-ltr .px-xl-3, .dir-rtl .pr-xl-3, .dir-rtl .px-xl-3 {
        padding-left: 1rem !important
    }

    .dir-rtl .pl-xl-3, .dir-rtl .px-xl-3 {
        padding-right: 1rem !important
    }

    .p-xl-4 {
        padding: 1.5rem !important
    }

    .pt-xl-4, .py-xl-4 {
        padding-top: 1.5rem !important
    }

    .pb-xl-4, .py-xl-4 {
        padding-bottom: 1.5rem !important
    }

    .dir-ltr .pr-xl-4, .dir-ltr .px-xl-4 {
        padding-right: 1.5rem !important
    }

    .dir-ltr .pl-xl-4, .dir-ltr .px-xl-4, .dir-rtl .pr-xl-4, .dir-rtl .px-xl-4 {
        padding-left: 1.5rem !important
    }

    .dir-rtl .pl-xl-4, .dir-rtl .px-xl-4 {
        padding-right: 1.5rem !important
    }

    .p-xl-5 {
        padding: 3rem !important
    }

    .pt-xl-5, .py-xl-5 {
        padding-top: 3rem !important
    }

    .pb-xl-5, .py-xl-5 {
        padding-bottom: 3rem !important
    }

    .dir-ltr .pr-xl-5, .dir-ltr .px-xl-5 {
        padding-right: 3rem !important
    }

    .dir-ltr .pl-xl-5, .dir-ltr .px-xl-5, .dir-rtl .pr-xl-5, .dir-rtl .px-xl-5 {
        padding-left: 3rem !important
    }

    .dir-rtl .pl-xl-5, .dir-rtl .px-xl-5 {
        padding-right: 3rem !important
    }

    .m-xl-n1 {
        margin: -.25rem !important
    }

    .mt-xl-n1, .my-xl-n1 {
        margin-top: -.25rem !important
    }

    .mr-xl-n1, .mx-xl-n1 {
        margin-right: -.25rem !important
    }

    .mb-xl-n1, .my-xl-n1 {
        margin-bottom: -.25rem !important
    }

    .ml-xl-n1, .mx-xl-n1 {
        margin-left: -.25rem !important
    }

    .m-xl-n2 {
        margin: -.5rem !important
    }

    .mt-xl-n2, .my-xl-n2 {
        margin-top: -.5rem !important
    }

    .mr-xl-n2, .mx-xl-n2 {
        margin-right: -.5rem !important
    }

    .mb-xl-n2, .my-xl-n2 {
        margin-bottom: -.5rem !important
    }

    .ml-xl-n2, .mx-xl-n2 {
        margin-left: -.5rem !important
    }

    .m-xl-n3 {
        margin: -1rem !important
    }

    .mt-xl-n3, .my-xl-n3 {
        margin-top: -1rem !important
    }

    .mr-xl-n3, .mx-xl-n3 {
        margin-right: -1rem !important
    }

    .mb-xl-n3, .my-xl-n3 {
        margin-bottom: -1rem !important
    }

    .ml-xl-n3, .mx-xl-n3 {
        margin-left: -1rem !important
    }

    .m-xl-n4 {
        margin: -1.5rem !important
    }

    .mt-xl-n4, .my-xl-n4 {
        margin-top: -1.5rem !important
    }

    .mr-xl-n4, .mx-xl-n4 {
        margin-right: -1.5rem !important
    }

    .mb-xl-n4, .my-xl-n4 {
        margin-bottom: -1.5rem !important
    }

    .ml-xl-n4, .mx-xl-n4 {
        margin-left: -1.5rem !important
    }

    .m-xl-n5 {
        margin: -3rem !important
    }

    .mt-xl-n5, .my-xl-n5 {
        margin-top: -3rem !important
    }

    .mr-xl-n5, .mx-xl-n5 {
        margin-right: -3rem !important
    }

    .mb-xl-n5, .my-xl-n5 {
        margin-bottom: -3rem !important
    }

    .ml-xl-n5, .mx-xl-n5 {
        margin-left: -3rem !important
    }

    .m-xl-auto {
        margin: auto !important
    }

    .mt-xl-auto, .my-xl-auto {
        margin-top: auto !important
    }

    .mr-xl-auto, .mx-xl-auto {
        margin-right: auto !important
    }

    .mb-xl-auto, .my-xl-auto {
        margin-bottom: auto !important
    }

    .ml-xl-auto, .mx-xl-auto {
        margin-left: auto !important
    }
}

@media (min-width: 1600px) {
    .m-xxl-0 {
        margin: 0 !important
    }

    .mt-xxl-0, .my-xxl-0 {
        margin-top: 0 !important
    }

    .mb-xxl-0, .my-xxl-0 {
        margin-bottom: 0 !important
    }

    .dir-ltr .mr-xxl-0, .dir-ltr .mx-xxl-0 {
        margin-right: 0 !important
    }

    .dir-ltr .ml-xxl-0, .dir-ltr .mx-xxl-0, .dir-rtl .mr-xxl-0, .dir-rtl .mx-xxl-0 {
        margin-left: 0 !important
    }

    .dir-rtl .ml-xxl-0, .dir-rtl .mx-xxl-0 {
        margin-right: 0 !important
    }

    .m-xxl-1 {
        margin: .25rem !important
    }

    .mt-xxl-1, .my-xxl-1 {
        margin-top: .25rem !important
    }

    .mb-xxl-1, .my-xxl-1 {
        margin-bottom: .25rem !important
    }

    .dir-ltr .mr-xxl-1, .dir-ltr .mx-xxl-1 {
        margin-right: .25rem !important
    }

    .dir-ltr .ml-xxl-1, .dir-ltr .mx-xxl-1, .dir-rtl .mr-xxl-1, .dir-rtl .mx-xxl-1 {
        margin-left: .25rem !important
    }

    .dir-rtl .ml-xxl-1, .dir-rtl .mx-xxl-1 {
        margin-right: .25rem !important
    }

    .m-xxl-2 {
        margin: .5rem !important
    }

    .mt-xxl-2, .my-xxl-2 {
        margin-top: .5rem !important
    }

    .mb-xxl-2, .my-xxl-2 {
        margin-bottom: .5rem !important
    }

    .dir-ltr .mr-xxl-2, .dir-ltr .mx-xxl-2 {
        margin-right: .5rem !important
    }

    .dir-ltr .ml-xxl-2, .dir-ltr .mx-xxl-2, .dir-rtl .mr-xxl-2, .dir-rtl .mx-xxl-2 {
        margin-left: .5rem !important
    }

    .dir-rtl .ml-xxl-2, .dir-rtl .mx-xxl-2 {
        margin-right: .5rem !important
    }

    .m-xxl-3 {
        margin: 1rem !important
    }

    .mt-xxl-3, .my-xxl-3 {
        margin-top: 1rem !important
    }

    .mb-xxl-3, .my-xxl-3 {
        margin-bottom: 1rem !important
    }

    .dir-ltr .mr-xxl-3, .dir-ltr .mx-xxl-3 {
        margin-right: 1rem !important
    }

    .dir-ltr .ml-xxl-3, .dir-ltr .mx-xxl-3, .dir-rtl .mr-xxl-3, .dir-rtl .mx-xxl-3 {
        margin-left: 1rem !important
    }

    .dir-rtl .ml-xxl-3, .dir-rtl .mx-xxl-3 {
        margin-right: 1rem !important
    }

    .m-xxl-4 {
        margin: 1.5rem !important
    }

    .mt-xxl-4, .my-xxl-4 {
        margin-top: 1.5rem !important
    }

    .mb-xxl-4, .my-xxl-4 {
        margin-bottom: 1.5rem !important
    }

    .dir-ltr .mr-xxl-4, .dir-ltr .mx-xxl-4 {
        margin-right: 1.5rem !important
    }

    .dir-ltr .ml-xxl-4, .dir-ltr .mx-xxl-4, .dir-rtl .mr-xxl-4, .dir-rtl .mx-xxl-4 {
        margin-left: 1.5rem !important
    }

    .dir-rtl .ml-xxl-4, .dir-rtl .mx-xxl-4 {
        margin-right: 1.5rem !important
    }

    .m-xxl-5 {
        margin: 3rem !important
    }

    .mt-xxl-5, .my-xxl-5 {
        margin-top: 3rem !important
    }

    .mb-xxl-5, .my-xxl-5 {
        margin-bottom: 3rem !important
    }

    .dir-ltr .mr-xxl-5, .dir-ltr .mx-xxl-5 {
        margin-right: 3rem !important
    }

    .dir-ltr .ml-xxl-5, .dir-ltr .mx-xxl-5, .dir-rtl .mr-xxl-5, .dir-rtl .mx-xxl-5 {
        margin-left: 3rem !important
    }

    .dir-rtl .ml-xxl-5, .dir-rtl .mx-xxl-5 {
        margin-right: 3rem !important
    }

    .p-xxl-0 {
        padding: 0 !important
    }

    .pt-xxl-0, .py-xxl-0 {
        padding-top: 0 !important
    }

    .pb-xxl-0, .py-xxl-0 {
        padding-bottom: 0 !important
    }

    .dir-ltr .pr-xxl-0, .dir-ltr .px-xxl-0 {
        padding-right: 0 !important
    }

    .dir-ltr .pl-xxl-0, .dir-ltr .px-xxl-0, .dir-rtl .pr-xxl-0, .dir-rtl .px-xxl-0 {
        padding-left: 0 !important
    }

    .dir-rtl .pl-xxl-0, .dir-rtl .px-xxl-0 {
        padding-right: 0 !important
    }

    .p-xxl-1 {
        padding: .25rem !important
    }

    .pt-xxl-1, .py-xxl-1 {
        padding-top: .25rem !important
    }

    .pb-xxl-1, .py-xxl-1 {
        padding-bottom: .25rem !important
    }

    .dir-ltr .pr-xxl-1, .dir-ltr .px-xxl-1 {
        padding-right: .25rem !important
    }

    .dir-ltr .pl-xxl-1, .dir-ltr .px-xxl-1, .dir-rtl .pr-xxl-1, .dir-rtl .px-xxl-1 {
        padding-left: .25rem !important
    }

    .dir-rtl .pl-xxl-1, .dir-rtl .px-xxl-1 {
        padding-right: .25rem !important
    }

    .p-xxl-2 {
        padding: .5rem !important
    }

    .pt-xxl-2, .py-xxl-2 {
        padding-top: .5rem !important
    }

    .pb-xxl-2, .py-xxl-2 {
        padding-bottom: .5rem !important
    }

    .dir-ltr .pr-xxl-2, .dir-ltr .px-xxl-2 {
        padding-right: .5rem !important
    }

    .dir-ltr .pl-xxl-2, .dir-ltr .px-xxl-2, .dir-rtl .pr-xxl-2, .dir-rtl .px-xxl-2 {
        padding-left: .5rem !important
    }

    .dir-rtl .pl-xxl-2, .dir-rtl .px-xxl-2 {
        padding-right: .5rem !important
    }

    .p-xxl-3 {
        padding: 1rem !important
    }

    .pt-xxl-3, .py-xxl-3 {
        padding-top: 1rem !important
    }

    .pb-xxl-3, .py-xxl-3 {
        padding-bottom: 1rem !important
    }

    .dir-ltr .pr-xxl-3, .dir-ltr .px-xxl-3 {
        padding-right: 1rem !important
    }

    .dir-ltr .pl-xxl-3, .dir-ltr .px-xxl-3, .dir-rtl .pr-xxl-3, .dir-rtl .px-xxl-3 {
        padding-left: 1rem !important
    }

    .dir-rtl .pl-xxl-3, .dir-rtl .px-xxl-3 {
        padding-right: 1rem !important
    }

    .p-xxl-4 {
        padding: 1.5rem !important
    }

    .pt-xxl-4, .py-xxl-4 {
        padding-top: 1.5rem !important
    }

    .pb-xxl-4, .py-xxl-4 {
        padding-bottom: 1.5rem !important
    }

    .dir-ltr .pr-xxl-4, .dir-ltr .px-xxl-4 {
        padding-right: 1.5rem !important
    }

    .dir-ltr .pl-xxl-4, .dir-ltr .px-xxl-4, .dir-rtl .pr-xxl-4, .dir-rtl .px-xxl-4 {
        padding-left: 1.5rem !important
    }

    .dir-rtl .pl-xxl-4, .dir-rtl .px-xxl-4 {
        padding-right: 1.5rem !important
    }

    .p-xxl-5 {
        padding: 3rem !important
    }

    .pt-xxl-5, .py-xxl-5 {
        padding-top: 3rem !important
    }

    .pb-xxl-5, .py-xxl-5 {
        padding-bottom: 3rem !important
    }

    .dir-ltr .pr-xxl-5, .dir-ltr .px-xxl-5 {
        padding-right: 3rem !important
    }

    .dir-ltr .pl-xxl-5, .dir-ltr .px-xxl-5, .dir-rtl .pr-xxl-5, .dir-rtl .px-xxl-5 {
        padding-left: 3rem !important
    }

    .dir-rtl .pl-xxl-5, .dir-rtl .px-xxl-5 {
        padding-right: 3rem !important
    }

    .m-xxl-n1 {
        margin: -.25rem !important
    }

    .mt-xxl-n1, .my-xxl-n1 {
        margin-top: -.25rem !important
    }

    .mr-xxl-n1, .mx-xxl-n1 {
        margin-right: -.25rem !important
    }

    .mb-xxl-n1, .my-xxl-n1 {
        margin-bottom: -.25rem !important
    }

    .ml-xxl-n1, .mx-xxl-n1 {
        margin-left: -.25rem !important
    }

    .m-xxl-n2 {
        margin: -.5rem !important
    }

    .mt-xxl-n2, .my-xxl-n2 {
        margin-top: -.5rem !important
    }

    .mr-xxl-n2, .mx-xxl-n2 {
        margin-right: -.5rem !important
    }

    .mb-xxl-n2, .my-xxl-n2 {
        margin-bottom: -.5rem !important
    }

    .ml-xxl-n2, .mx-xxl-n2 {
        margin-left: -.5rem !important
    }

    .m-xxl-n3 {
        margin: -1rem !important
    }

    .mt-xxl-n3, .my-xxl-n3 {
        margin-top: -1rem !important
    }

    .mr-xxl-n3, .mx-xxl-n3 {
        margin-right: -1rem !important
    }

    .mb-xxl-n3, .my-xxl-n3 {
        margin-bottom: -1rem !important
    }

    .ml-xxl-n3, .mx-xxl-n3 {
        margin-left: -1rem !important
    }

    .m-xxl-n4 {
        margin: -1.5rem !important
    }

    .mt-xxl-n4, .my-xxl-n4 {
        margin-top: -1.5rem !important
    }

    .mr-xxl-n4, .mx-xxl-n4 {
        margin-right: -1.5rem !important
    }

    .mb-xxl-n4, .my-xxl-n4 {
        margin-bottom: -1.5rem !important
    }

    .ml-xxl-n4, .mx-xxl-n4 {
        margin-left: -1.5rem !important
    }

    .m-xxl-n5 {
        margin: -3rem !important
    }

    .mt-xxl-n5, .my-xxl-n5 {
        margin-top: -3rem !important
    }

    .mr-xxl-n5, .mx-xxl-n5 {
        margin-right: -3rem !important
    }

    .mb-xxl-n5, .my-xxl-n5 {
        margin-bottom: -3rem !important
    }

    .ml-xxl-n5, .mx-xxl-n5 {
        margin-left: -3rem !important
    }

    .m-xxl-auto {
        margin: auto !important
    }

    .mt-xxl-auto, .my-xxl-auto {
        margin-top: auto !important
    }

    .mr-xxl-auto, .mx-xxl-auto {
        margin-right: auto !important
    }

    .mb-xxl-auto, .my-xxl-auto {
        margin-bottom: auto !important
    }

    .ml-xxl-auto, .mx-xxl-auto {
        margin-left: auto !important
    }
}

.text-monospace {
    font-family: 'SFMono-Regular', Consolas, 'Liberation Mono', Menlo, Courier, monospace !important
}

.text-justify {
    text-align: justify !important
}

.text-wrap {
    white-space: normal !important
}

.text-nowrap {
    white-space: nowrap !important
}

.text-truncate {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap
}

.text-left {
    text-align: left !important
}

.text-right {
    text-align: right !important
}

.text-center {
    text-align: center !important
}

@media (min-width: 576px) {
    .text-sm-left {
        text-align: left !important
    }

    .text-sm-right {
        text-align: right !important
    }

    .text-sm-center {
        text-align: center !important
    }
}

@media (min-width: 768px) {
    .text-md-left {
        text-align: left !important
    }

    .text-md-right {
        text-align: right !important
    }

    .text-md-center {
        text-align: center !important
    }
}

@media (min-width: 992px) {
    .text-lg-left {
        text-align: left !important
    }

    .text-lg-right {
        text-align: right !important
    }

    .text-lg-center {
        text-align: center !important
    }
}

@media (min-width: 1400px) {
    .text-xl-left {
        text-align: left !important
    }

    .text-xl-right {
        text-align: right !important
    }

    .text-xl-center {
        text-align: center !important
    }
}

.text-lowercase {
    text-transform: lowercase !important
}

.text-uppercase {
    text-transform: uppercase !important
}

.text-capitalize {
    text-transform: capitalize !important
}

.font-weight-light {
    font-weight: 300 !important
}

.font-weight-lighter {
    font-weight: lighter !important
}

.font-weight-normal {
    font-weight: 400 !important
}

.font-weight-semibold {
    font-weight: 500 !important
}

.font-weight-bold {
    font-weight: 700 !important
}

.font-weight-bolder {
    font-weight: bolder !important
}

.font-italic {
    font-style: italic !important
}

.text-white {
    color: #fff !important
}

.text-primary {
    color: #3e79f7 !important
}

a.text-primary:focus, a.text-primary:hover {
    color: #094ddf !important
}

.text-secondary {
    color: #a461d8 !important
}

a.text-secondary:focus, a.text-secondary:hover {
    color: #7f2fbe !important
}

.text-success {
    color: #04d182 !important
}

a.text-success:focus, a.text-success:hover {
    color: #038653 !important
}

.text-info {
    color: #3e79f7 !important
}

a.text-info:focus, a.text-info:hover {
    color: #094ddf !important
}

.text-warning {
    color: #ffc542 !important
}

a.text-warning:focus, a.text-warning:hover {
    color: #f4a900 !important
}

.text-danger {
    color: #ff6b72 !important
}

a.text-danger:focus, a.text-danger:hover {
    color: #ff1f29 !important
}

.text-gray {
    color: #455560 !important
}

a.text-gray:focus, a.text-gray:hover {
    color: #252e33 !important
}

.text-gray-light {
    color: #72849a !important
}

a.text-gray-light:focus, a.text-gray-light:hover {
    color: #505e70 !important
}

.text-gray-lighter {
    color: #ededed !important
}

a.text-gray-lighter:focus, a.text-gray-lighter:hover {
    color: #c7c7c7 !important
}

.text-gray-lightest {
    color: #f7f7f8 !important
}

a.text-gray-lightest:focus, a.text-gray-lightest:hover {
    color: #ceced4 !important
}

.text-dark {
    color: #1a3353 !important
}

a.text-dark:focus, a.text-dark:hover {
    color: #080f19 !important
}

.text-body {
    color: #455560 !important
}

.text-muted {
    color: rgba(114, 132, 154, .7) !important
}

.text-black-50 {
    color: rgba(0, 0, 0, .5) !important
}

.text-white-50 {
    color: rgba(255, 255, 255, .5) !important
}

.text-hide {
    font: 0/0 a;
    color: transparent;
    text-shadow: none;
    background-color: transparent;
    border: 0
}

.text-decoration-none {
    text-decoration: none !important
}

.text-break {
    word-break: break-word !important;
    overflow-wrap: break-word !important
}

.text-reset {
    color: inherit !important
}

.font-size-xxl {
    font-size: 30px !important
}

.font-size-xl {
    font-size: 22px !important
}

.font-size-lg {
    font-size: 21px !important
}

.font-size-md {
    font-size: 17px !important
}

.font-size-base {
    font-size: 14px !important
}

.font-size-sm {
    font-size: 12px !important
}

.font-size-xs {
    font-size: 10px !important
}

.visible {
    visibility: visible !important
}

.invisible {
    visibility: hidden !important
}
